import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { getUserCollections, addNewCollection, deleteCollection, combineCollectionItems} from './API';
import ItemsList from './ItemsList';
import Confirm from './Confirm';
import Tooltip from '@mui/material/Tooltip';
import Notification from './Notification';


// props: collectionId, subscribed
export default function Collections(props) {

  const [collections, setCollections] = useState([]);
  const [selected, setSelected] = useState();
  const [showAdd, setShowAdd] = useState(false);
  const [collectionName, setCollectionName] = useState();
  const [confirmDeleteCollection, setConfirmDeleteCollection] = useState();
  const [message, setMessage] = useState("");


  useEffect(()=>{
    if (props.loggedIn)
      loadCollections();
  }, [])

  function loadCollections(){
       getUserCollections().then((res)=>{
              setCollections(res.data.filter((e)=>{return e.collectionType == 'CUSTOM' }));
              if (props.collectionId) {
                setSelected(res.data.find(c=> c.id==props.collectionId));
              }
              else if (selected)
                setSelected(res.data.find(c=> c.id==selected.id));
      });
  }

  function addNew(){
    addNewCollection(collectionName).then((res)=>{
        setCollections(collections.concat([res.data]));
        setShowAdd(false);
    });
  }
  function deleteSelectedCollection(){
    // if (window.confirm("Kas soovid kustutada kogumikku "+selected.name + "?")){
        deleteCollection(selected).then((res)=>{
            loadCollections();
            setSelected(null);
        });
  }
  function itemRemoved(item){
      loadCollections();
  }
  function print(){
    combineCollectionItems(selected).then((res)=>{
        window.location.href = res.data.combined_pdf;
    }, 
    ()=>{

    });

  }

  if (!collections){
      return (<p>Sisu puudub.</p>);
  }
  else
    return(
      <div>
        <Notification message={message} setMessage={setMessage}/>
        <Confirm showModal={confirmDeleteCollection} setShowModal={setConfirmDeleteCollection} subtitle="Oled kindel, et soovid kogumiku kustutada?" confirm={deleteSelectedCollection}/>
        <div id="collections" className="columns">
              <div className="column is-one-fifth p-0">
                <h1 className="title is-5">Kogumikud:</h1>
                <ul className="menu-list">
                {collections.map((c, i)=>
                  <Link to={'/dashboard/collections/'+c.id} key={c.id} className={(selected && selected.id == c.id ? 'is-active':'')} onClick={()=>{setSelected(c)}}>
                    {c.name} {c.items && ("(" +c.items.length + ")")}
                  </Link>
                )}
                  <li className="menu-item "><a onClick={()=>{setShowAdd(true)}}>
                      <i className="fa-solid fa-plus"></i> Lisa uus
                  </a></li>
                </ul>
                {showAdd && 
                    <div className={"has-text-centered is-green p-2 transition " + (showAdd ? '':'hide')}>
                     <p>Nimi: <i className="fa-solid fa-xmark is-pulled-right" onClick={()=>{setShowAdd(false)}}></i></p>
                      <p><input onChange={(v)=>{setCollectionName(v.target.value)}} className="input" name="collectionname" placeholder="Kogumiku nimi"/></p>
                      <p className="has-text-centered"><button className="button is-small" onClick={addNew}>Sisesta</button></p>
                    </div>
                }
              </div>
              <div className="column p-0 pl-2">
                  {selected &&
                      <h1 className="title is-4">{selected.name}</h1>
                  }
                  {selected && selected.items && selected.items.length > 0 &&
                        <ItemsList 
                        items={selected.items}
                        setItems={setSelected}
                        loggedIn={props.loggedIn} 
                        collection={selected}
                        setCollection={setSelected}
                        removeItem={itemRemoved}
                        update={loadCollections}
                        subscribed={props.subscribed}
                      />
                  }
                  {selected && selected.items && selected.items.length == 0 &&
                      <div className="m-5 mt-6">
                        <h5>... siia ei ole veel midagi lisatud</h5>
                      </div>
                  }
                  {selected &&
                    <div>
                        <hr/>
                        <p className="">
                          <button className="button is-small" onClick={()=>{setConfirmDeleteCollection(true)}}><i className="fa-regular fa-trash-can mr-1"></i> Kustuta kogumik</button>
                          {selected && selected.items && selected.items.length < 1 &&
                                  <button className="button is-small" disabled={true} onClick={print}><i className="fa-solid fa-print mr-1"></i> Prindi </button>
                          }
                          {selected && selected.items && selected.items.length>0 &&
                             <Tooltip title="Lihtsustatud printimiseks liidetakse kõik kogumiku materjalid ühte faili.">
                                  <button className="button is-small"  onClick={print}><i className="fa-solid fa-print mr-1"></i> Prindi </button>
                            </Tooltip>
                          }
                        </p>
                    </div>
                  }
              </div>

        </div>
      </div>
    );
}

