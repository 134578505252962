import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom'
import Tooltip from '@mui/material/Tooltip';

import { getContentItems, getUserPreferences, likeItem, dislikeItem, getAgeGroups } from './API';
import LoginModal from './LoginModal';
import ItemsList from './ItemsList';
import AddToCalendarModal from './AddToCalendarModal';
import ItemActionButtons from './ItemActionButtons';
import Breadcrumbs from './Breadcrumbs';


// QUERIES ITEMS AND CONTROLS WHATS VISIBLE AND WHATS NOT
// INCLUDES FILTERS
// props: loggedIn, path, subscribed
export default function ContentList(props) {

  let { domainId, categoryId } = useParams();

  const [allItems, setAllItems] = useState(null);
  const [visibleItems, setVisibleItems] = useState();
  const [preferences, setPreferences] = useState();

  // FILTREERIMISED
  // inputi jaoks ainult
  const [search, setSearch] = useState("");
  // const [level, setLevel] = useState();
  // const [ageGroup, setAgeGroup] = useState();
  const [ageGroups, setAgeGroups] = useState();

  // ühine filter teha
  const [filter, setFilter] = useState({search:"", level:null, ageGroup:null});



  useEffect(()=>{
        getAgeGroups().then((res)=>{
            setAgeGroups(res.data);
        });
  }, [])

// get all items
  useEffect(()=>{
    if (categoryId) {
        getContentItems(categoryId).then((res)=>{
              setAllItems(res.data);
        });    
    }
    else if(domainId){
            getContentItems(domainId).then((res)=>{
                setAllItems(res.data);
        });        
    }
    
  }, [props.path]);


  function resetFilter(){
        setFilter({search:"", level:null, ageGroup:null});
  }

// reset filter
  useEffect(()=>{
            // setLevel(null);
            // setSearch("");
            // setAgeGroup(null);
            resetFilter();
  }, [props.domain]);

// set hidden and liked ids
  useEffect(()=>{
    if (allItems != null && !preferences){
        if (props.loggedIn){
            getUserPreferences().then((res)=>{
                setPreferences(res.data);
                });
        }
    }
  }, [allItems]);

useEffect(()=>{
    if (preferences && preferences.disliked && preferences.disliked.length > 0){
        // setAllItems(allItems.filter((e)=>{return !preferences.disliked.includes(e.id)}));
    }
  }, [preferences]);

// set visible items, filteri jaoks, et ei peaks uuesti pärima
  // useEffect(()=>{
  //     if (allItems) {
  //       var visible = Array();
  //           if (search && level) {
  //               visible = allItems.filter((e)=>{
  //                   // leveli filter
  //                   return e.level==level &&
  //                   // ja search filter
  //                   (
  //                       e.name.toLowerCase().includes(search.toLowerCase()) || 
  //                       e.desc.toLowerCase().includes(search.toLowerCase()) 
  //                   )
  //                   ;
  //               }
  //                   );
  //           }
  //           else if (search) {
  //               visible = allItems.filter((e)=>{
  //                   return (e.name.toLowerCase().includes(search.toLowerCase()) || e.desc.toLowerCase().includes(search.toLowerCase()))});
  //           }
  //           else if (level) {
  //               visible = allItems.filter((e)=>{return e.level==level});
  //           }
  //           else {
  //               visible = allItems
  //           }
  //           setVisibleItems(sortList(visible));
  //     }
  // }, [allItems, search, level]);


  //  FILTER LIST
    useEffect(()=>{
      if (allItems) {
        // kuidas seda teha... mingi väärtus peab neil olemas olema eks... siis ei pea midagi eraldi tšekkima...
        // ja siis... võtad esimese filtri... ja siis rakendad talle teist... järjekord ei ole oluline eks...
        // lõpuks on ikkagi, et peab olema ühes ja teises... 
        let search_result = [...allItems];
        // SEARCH
        if (filter.search) {
            search_result = allItems.filter((e)=>{
                let match = e.name.toLowerCase().includes(filter.search.toLowerCase()) || e.desc.toLowerCase().includes(filter.search.toLowerCase());
                return match;
            })
        }
        // LEVEL
        let level_result = [...allItems];
        if (filter.level) {
            level_result = allItems.filter((e)=>{return e.level==filter.level});
        }
        // AGEGROUP
        let agegroup_result = [...allItems];
        if (filter.ageGroup) {
            agegroup_result = allItems.filter((e)=>{return e.ageGroup==filter.ageGroup.id});
        }

        let visible = _.intersection(search_result, level_result, agegroup_result);
        setVisibleItems(sortList(visible));
      }
  }, [allItems, filter])


// preferences affect visibleitems only that have value hidden and done
    useEffect(()=>{
        if(preferences && visibleItems && preferences.disliked && preferences.done){
            setVisibleItems(sortList(visibleItems.filter((e)=>{return !preferences.disliked.includes(e.id) && !preferences.done.includes(e.id)})));
        }
  }, [preferences]);

    // sort by date_created
    function sortList(list){
        if (!list)
            list = [...visibleItems];
        list.sort((a,b)=>{return new Date(b.date_created)-new Date(a.date_created)});
        if (!props.loggedIn)
            list.sort((a,b)=>{return b.is_free - a.is_free })
        return list;
    }

    // FILTERING FUNCTIONS
    // SEARCH
    function filterSearch(event){
        let f = {...filter};
        f.search = event.target.value;
        setFilter(f);
    }
    // LEVEL
    function filterLevel(val){
        let f = {...filter};
        if (val == filter.level) {
            f.level = null;
        }
        else
            f.level = val;
        setFilter(f);
    }
    // AGE GROUP
    function filterByAgeGroup(g){
        let f  = {...filter};
        if (filter.ageGroup && filter.ageGroup.id == g.id){
            f.ageGroup = null;
        }
        else {f.ageGroup = g}
        setFilter(f);
    }

    // SORTING
    function sortFree(){
        let list = [...visibleItems];
        setVisibleItems(list.sort((a,b)=>{return b.is_free - a.is_free }))
    }

    return (
        <div id="contentlist">
                <div className="columns">
                                <div className="column">
                                    <Breadcrumbs domain={props.domain} contentItem={props.contentItem} category={props.category} categoryId={props.categoryId} />
                                    <div className="field mt-3">
                                            <p className="control has-icons-left has-icons-right">
                                                    <input className="input search" placeholder='Otsi...' value={filter.search} onChange={filterSearch}/>
                                                    <span className="icon is-small is-left">
                                                            <i className="fa-solid fa-magnifying-glass"></i>
                                                    </span>
                                            </p>
                                    </div>
                                    <div className="row">
                                                {ageGroups && ageGroups.map((g, i)=>
                                                    <button key={i} className={"button is-small m-1 " + (filter.ageGroup && filter.ageGroup.id==g.id ? 'is-active is-primary':'')} onClick={()=>{filterByAgeGroup(g)}}>{g.name}</button>
                                                )}
                                        </div>
                                     <div className="row">
                                              <button className={"button is-primary is-small m-1 " + (filter.level==1 ? 'is-active':'is-light')} onClick={()=>{filterLevel(1)}}>Tase 1</button>
                                              <button className={"button is-primary is-small m-1 " + (filter.level==2 ? 'is-active':'is-light')} onClick={()=>{filterLevel(2)}}>Tase 2</button>
                                              <button className={"button is-primary is-small m-1 " + (filter.level==3 ? 'is-active':'is-light')} onClick={()=>{filterLevel(3)}}>Tase 3</button>
                                              <button className={"button is-primary is-small m-1 " + (filter.level==4 ? 'is-active':'is-light')} onClick={()=>{filterLevel(4)}}>Tase 4</button>
                                              <button className={"button is-primary is-small m-1 " + (filter.level==5 ? 'is-active':'is-light')} onClick={()=>{filterLevel(5)}}>Tase 5</button>
                                        </div>
                                </div>
                                {props.category &&
                                    <div className="column">
                                        <p className="category-desc"><span className="title is-6 is-uppercase">{props.category.name}<br/></span> {props.category.desc}</p>
                                    </div>
                                }
                </div>

                <div>
                                    <div className="columns">
                                       
                                  
                                    </div>
                                    <hr className="m-1" />

                    <ItemsList subscribed={props.subscribed} preferences={preferences} setPreferences={setPreferences} items={visibleItems} setItems={setVisibleItems} loggedIn={props.loggedIn}/>
                </div>
        </div>
    );
}

