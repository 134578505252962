import React from 'react';

export default function ErrorPage() {
  return(
   
      <div className="container">
        <div className="notification is-danger m-6 p-5">Midagi läks valesti...</div>
      </div>
  );
}

