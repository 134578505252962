import React, { useState } from 'react';
import { Link } from 'react-router-dom';



export default function LoginModal(props) {
  
  return(
   

   <div id="loginmodal" className={"modal " + (props.showModal ? "is-active" : "")}>
          <div className="modal-background"></div>
          <div className="modal-content">
            <div className="box has-text-centered">
                <h1 className="title">Toiming on võimalik ainult sisselogitud kasutajatele</h1>
                <p>Kas soovite sisse logida?</p>
                <p className="mt-3">
                    <button className="button" aria-label="close" onClick={()=>{props.setShowLoginModal(false)}}>Sulge</button>
                    <Link to="/login" className="button is-primary" aria-label="close">Logi sisse</Link>
                </p>
            </div>
          </div>
        </div>

                  
  );
}

