import React, { useEffect, useState } from 'react';
import { useInView } from "react-intersection-observer";


export default function ScrollUp() {

    const { ref, inView } = useInView({
        threshold: 0
    });

    useEffect(()=>{

    }, [inView]); 

    function scroll(){
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });   
     }

  return(
    <div>
        <div id="scrollup" className="has-text-centered">
            <button className={'button is-rounded ' + (inView ? '':'showscrollbtn')} onClick={scroll}>
                <div className="buttondiv">
                     <i className="fa-solid fa-chevron-up"></i> 
                    <span className="p-4 uppercase"> Üles </span>
                    <i className="fa-solid fa-chevron-up"></i>
                </div>
                </button>
        </div>
        <div ref={ref} id="scrollObserver"></div>
    </div>
  );
}



