import React, { useEffect, useState } from 'react';
import { useNavigate, Link, useParams } from "react-router-dom";
import {  } from './API';


export default function Proposal(props) {

  let { status } = useParams();
  const [active, setActive] = useState(0);


  useEffect(()=>{

  },[])

  return(
   
      <div className="container">
      </div>
  );
}

