import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { getCarousal } from './API';

import GamesHero from './GamesHero';


export default function Tabs(props) {

  const location = useLocation();
  const navigate = useNavigate();


  let noTabsPaths = ['/login', '/register', '/subscribe'];
  let noHeroPaths = ['/login', '/register', '/subscribe', '/dashboard', '/error', '/contact', '/aboutcookies'];
  const [themeClass, setThemeClass] = useState('is-stories');
  const [showHero, setShowHero] = useState(false);
  const [showTabs, setShowTabs] = useState(true);
  const [carousal, setCarousal] = useState([]);
  // carousal active item (index)
  const [active, setActive] = useState(0);
  const [previous, setPrevious] = useState(0);
  const [clock, setClock] = useState(new Date());
  const [pause, setPause] = useState(false);
  const [mouseOnCarousal, setMouseOnCarousal] = useState();
  

  useEffect(()=>{

      getCarousal().then((res)=>{
          setCarousal(res.data);
      });

      const interval = setInterval(() => {
          setClock(new Date());
      }, 6000);
      return () => clearInterval(interval);

  }, []);

  useEffect(()=>{
      if (mouseOnCarousal)
        return;
        if(pause){
          setPause(false);
        }
        else
          showCarousal(1);
  },[clock])

  useEffect(()=>{

    // kui pole path domain... siis setDomain null
    if (!location.pathname.startsWith('/domain')){
      props.setDomain(null);
    }
    // SET THEME BY DOMAIN
    if (props.domain){
      switch(props.domain.id){
      case 2:
        setThemeClass('is-worksheets');
        break;
        case 3:
        setThemeClass('is-games');
        break;
      default:
        setThemeClass('is-stories');
        break;
      }
    }
    // SHOW TABS? SHOW HERO?
      if (location.pathname=="/")
      {
        setShowHero(true);
      }
      else {
          setShowHero(false);
      }
      setShowTabs(true);
      // const herosmatches = noHeroPaths.filter(s => location.pathname.startsWith(s));
      // const tabsmatches = noTabsPaths.filter(s => location.pathname.startsWith(s));
      // herosmatches.length > 0 ? setShowHero(false): setShowHero(true);
      // tabsmatches.length > 0 ? setShowTabs(false): setShowTabs(true);

  }, [location.pathname, props.domain]);


  function showCarousal(increase){
      setPrevious(active);
      var n = active + increase;
      if (n<0){
        setActive(carousal.length-1);
      }
      else if (n > carousal.length-1){
        setActive(0);
      }
      else{
        setActive(n);
      }
  }

  return(

    <div id="hero-tabs">
       <section className={'hero is-primary ' + themeClass}>
       <div className="carousal" onMouseEnter={() => setMouseOnCarousal(true)} onMouseLeave={() => setMouseOnCarousal(false)}>
        {showHero && carousal && carousal.length > 1 && 
            <div className="arrow"><span className="left clickable" onClick={()=>{setPause(true);showCarousal(-1)}} ><i className="fa-solid fa-chevron-left"></i></span></div>
        }
        {showHero && carousal && carousal.length > 1 && 
            <div className="arrow"><span className="right clickable" onClick={()=>{setPause(true);showCarousal(1)}}><i className="fa-solid fa-chevron-right"></i></span></div>
        }

           {showHero && carousal && carousal.length > 0 &&  carousal.map((item, i)=>
              <div key={i} className={(showHero ?'':'showNoHero') + " carousalitem hero-body " + ( i < active ? 'left': (i > active ? 'right' : 'active')) + (i==previous?' previous':'')}>
                <div className="container has-text-centered">
                    <div className="columns">
                      <div className="column">
                        <p className="title"> {item.title}</p>
                        <p className="subtitle"> {item.subtitle}</p>
                        <p className="content"> {item.content}</p>
                      </div>
                    </div>
                </div>
              </div>
          )}
           {/*PEIDETUD, ainult kõrguse jaoks*/}
          {showHero && carousal && carousal.length > 0 &&  carousal.map((item, i)=>
              <div key={i} className={(showHero ?'':'showNoHero') + " carousalitem hero-body hidden"}>
                <div className="container has-text-centered">
                    <div className="columns">
                      <div className="column">
                        <p className="title"> {item.title}</p>
                        <p className="subtitle"> {item.subtitle}</p>
                        <p className="content"> {item.content}</p>
                      </div>
                    </div>
                </div>
              </div>
          )}
{/*          {showHero && carousal.length == 0 &&
              <div  className={(showHero ?'':'showNoHero') + " carousalitem hero-body"}>
                <div className="container has-text-centered">
                    <div className="columns">
                      <div className="column">
                        <p className="title"> KOKOmini - nutikad lapsed</p>
                        <p className="subtitle"> Keskkond eestikeelsete materjalidega lastele lahendamiseks, mängimiseks, õppimiseks. </p>
                        <p className="content">  </p>
                      </div>
                    </div>
                </div>
              </div>
          }*/}
        </div>

          <div className="hero-foot">
            <nav className="tabs is-boxed is-fullwidth">
              <div className="container">
                <ul className={ (showTabs ?'':'showNoHero')}>
                {props.domains.map((d, i) => 
                    <li key={d.id} className={props.domain && props.domain.id == d.id ? 'is-active' : ''}>
                        <a onClick={()=>{navigate('/domain/'+d.id)}}> {d.name} </a>
                    </li>
                )}

                </ul>
              </div>
            </nav>
          </div>
        </section>
        <div className="is-clearfix"></div>
      </div>
  );
}

