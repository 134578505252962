import React, { useEffect, useState } from 'react';

// props: title, subtitle, message
// props funcs: confirm, cancel
export default function Notification(props) {

  useEffect(()=>{
  }, [props.showModal]);

  function close(){
      if (props.cancel){
        props.cancel();
      }
      props.setShowModal(false);
  }
  function confirm(){
      props.confirm();
      props.setShowModal(false);

  }

  return(
   
    // Näidis kasutamiseks:
    // <Confirm showModal={showConfirm} setShowModal={setShowConfirm} subtitle="Oled kindel, et soovid kogumiku kustutada?" confirm={confirm}/>


      <div className={'modal notification-modal '+(props.showModal ? 'is-active':'hidden')}>
          <div className="modal-background"></div>
              <div className="modal-content">

                {/* SISU */}
                  <div className="box has-text-centered">
                  {!props.automatic &&
                    <div>
                      <span className="is-pulled-right"><i className="fa-solid fa-xmark is-pulled-right" onClick={close}></i></span>
                      <span className="is-clearfix"></span>
                    </div>
                  }
                  {props.title &&
                      <h1 className="title">{props.title}</h1>
                  }
                  {props.subtitle &&
                      <h1 className="subtitle">{props.subtitle}</h1>
                  }
                  {typeof props.notification == 'string' &&
                      <p>{props.notification}</p>
                  }

                    {/* BUTTON */}
                    <div className="has-text-centered">
                              <p className="mt-3">
                                  <button className="button" aria-label="close" onClick={close}>Tagasi</button>
                                  <button className="button is-primary ml-2 " aria-label="close" onClick={confirm}>Kinnitan</button>
                              </p>
                    </div>


                  </div>
              </div>  
      </div>
  );
}

