import React, { useEffect, useState } from 'react';
import { BrowserRouter, Router, Routes, Route, Navigate } from 'react-router-dom';
import DomainPage from './DomainPage';
import DomainItemPage from './DomainItemPage';

// 
export default function DomainRoute(props) {

  const [categories, setCategories] = useState();
  const [category, setCategory] = useState();

  useEffect(()=>{
  }, [props.domains]);


  return(
   
      <div>
          <Routes>
              <Route index  element={<Navigate to="/"/>} />
              <Route path=":domainId" element={<DomainPage subscribed={props.subscribed} domains={props.domains} setDomain={props.setDomain}  account={props.account} loggedIn={props.loggedIn}                      />} />
              <Route path=":domainId/page/:pageId" element={<DomainPage subscribed={props.subscribed} domains={props.domains} setDomain={props.setDomain} account={props.account} loggedIn={props.loggedIn}          />} />
              <Route path=":domainId/category/:categoryId" element={<DomainPage subscribed={props.subscribed} domains={props.domains} setDomain={props.setDomain} account={props.account} loggedIn={props.loggedIn}         />} />
              {/*<Route path=":domainId/category/:categoryId/page/:pageId" element={<DomainPage subscribed={props.subscribed} domains={props.domains} setDomain={props.setDomain} account={props.account} loggedIn={props.loggedIn}    />} />*/}
              <Route path=":domainId/item/:itemId" element={<DomainPage subscribed={props.subscribed} domains={props.domains} setDomain={props.setDomain} account={props.account} loggedIn={props.loggedIn}      />}/>
              <Route path=":domainId/category/:categoryId/item/:itemId" element={<DomainPage subscribed={props.subscribed} domains={props.domains} setDomain={props.setDomain} account={props.account} loggedIn={props.loggedIn}     />} />
          </Routes>
      </div>
  );
}

