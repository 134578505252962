import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';

import Tabs from './Tabs';
import SidebarCategories from './SidebarCategories';
import ContentList from './ContentList';
import ItemsList from './ItemsList';
import DomainItemPage from './DomainItemPage';
import { getCategory, getContentItem } from './API';
import Breadcrumbs from './Breadcrumbs';
import AccountSidebar from './AccountSidebar';
import Notification from './Notification';


// QUERIES CATEGORIES, DOMAIN, ITEM
// props: subscribed
export default function DomainPage(props) {

  let { domainId, categoryId, itemId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const [accessNotification, setAccessNotification] = useState();

  const [contentItem, setContentItem] = useState();
  const [category, setCategory] = useState();
  const [categories, setCategories] = useState();
  const [domain, setDomain] = useState();
  const [collections, setCollections] = useState([{name:"Autod", id:2},{name:"Kiisud", id:23}]);
  const [selected, setSelected] = useState();

// setDomain, setCategory, setContentItem - kui paramsis olemas
  useEffect(()=>{
  }, [])

  useEffect(()=>{
    if (domain && domainId == domain.id){

    }
    else if(domainId){
        getCategory(domainId).then((res)=>{
            setDomain(res.data);
            props.setDomain(res.data);
        });
    }
    else {
        setDomain(null);
        props.setDomain(null);
    }
    if(categoryId){
        getCategory(categoryId).then((res)=>{
            setCategory(res.data);
        });
    }
    else {
        setCategory(null);
    }
    if(itemId){
        getContentItem(itemId).then((res)=>{
            // if (!props.subscribed && !res.data.is_free){
            //     setAccessNotification("Ligipääs ainult liitunud kasutajatele.");
            //     return;
            // }
            setContentItem(res.data);
        });
    }
    else {
        setContentItem(null);
    }
  }, [location.pathname]);


  return(
      <div id="domainpage">
        <Notification message={accessNotification} setMessage={setAccessNotification} close={()=>{navigate(-1)}}/>

          <div id="contentpage" className="container is-fluid">
              <div className="columns">      
                   <div className="sidebar-categories column is-one-quarter">
                      <SidebarCategories isItemPage={itemId} domain={domain} categoryId={categoryId} categories={categories} category={category} setCategories={setCategories} path={location.pathname}/>
                      {/* COLLECTION MENU */}
                      <div className="mt-3 is-hidden-mobile">
                      <AccountSidebar />

                      </div>
                    {/**/}
                   </div>
                   <div className="column">

                       <div className="contentcolumn">
                            {itemId &&
                            <div>
                                    <Breadcrumbs domain={domain} contentItem={contentItem} category={category} categoryId={categoryId} />
                            </div>
                            }
                            {!itemId && 
                                    <ContentList subscribed={props.subscribed} path={location.pathname} loggedIn={props.loggedIn} domain={domain} category={category} categoryId={categoryId} contentItem={contentItem}/>
                            }
                            {itemId &&
                                    <DomainItemPage subscribed={props.subscribed} itemId={itemId} categoryId={categoryId} contentItem={contentItem} setContentItem={setContentItem}  account={props.account} loggedIn={props.loggedIn}/>
                            }
                       </div>
                   </div>

              </div>
          </div>
      </div>
  );
}

