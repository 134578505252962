import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, Link, useParams } from 'react-router-dom'

import ItemsList from './ItemsList';
import { getFavourites } from './API';

// props: subscribed
export default function Favourites(props) {

  const [items, setItems] = useState();


  useEffect(()=>{
    if(props.preferences)
          getFavourites().then((res)=>{
              setItems(res.data);
          });
  }, [props.preferences])

  function removeItem(item){
      setItems(items.filter((e)=>{return e.id != item.id}));
  }

    return (
            <ItemsList subscribed={props.subscribed} title="Lemmikud" preferences={props.preferences} setPreferences={props.setPreferences} items={items} setItems={setItems} loggedIn={props.loggedIn} disliked={true}/>
               
    )

}