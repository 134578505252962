import React, { useEffect, useState } from 'react';
import { getDatelessCalendarItems, addItemToCalendar, toggleDoneCalendarItem, deleteCalendarItem } from './API';
import Tooltip from '@mui/material/Tooltip';

export default function Todo(props){

  const [todos, setTodos] = useState();
  const [showAdd, setShowAdd] = useState();
  const [newNote, setNewNote] = useState("");


  useEffect(()=>{
      loadNotes();
  }, []);

  function loadNotes(){
          getDatelessCalendarItems().then((res)=>{
          setTodos(res.data);
      });
  }

  function onNoteChange(event){
      setNewNote(event.target.value);
      setShowAdd(event.target.value.length > 0);
  }
  function saveNote(){
    addItemToCalendar({item:{},content:newNote, itemType:'note'}).then((res)=>{
        loadNotes();
        setNewNote("");
        setShowAdd(false);

    });
  }
  function setDone(note) {
      note.done = !note.done;
      if(!note.item){
        note.item = {};
      }
      toggleDoneCalendarItem(note).then((res)=>{
        loadNotes();
      });
  }

  function onKeyDown(event){
    if (event.key === 'Enter') {
      saveNote();
    }
  }

  function deleteNote(item){
      deleteCalendarItem(item).then(loadNotes);
  }

  return (
   
      <div id="todos" className="">
          <h1 className="title is-5">Märkmed</h1>
          {todos && todos.map((e,i)=>
                <div key={i} className="note"> 
                    <label className="checkbox">
                        <input type="checkbox" checked={e.done} onChange={()=>{setDone(e)}}/>
                        <span className={e.done ? 'done':''}>{e.content}</span>
                        <span>{e.item && e.item.name}</span>
                    </label>
                    <Tooltip title="Kustuta" className="deletenote"><i className="clickable ml-2 fa-solid fa-xmark" onClick={()=>{deleteNote(e)}}></i></Tooltip>
                </div>
            )
          }

              <div>
                <input placeholder="lisa..." type="text" className="input noteinput mt-2 mb-2" value={newNote} onChange={onNoteChange} onKeyDown={onKeyDown}/>
                {/*<button className="button is-small" onClick={()=>{setShowAdd(false);setNewNote("");}}>Katkesta</button>*/}
          {showAdd && 
                <button className="button is-primary is-light is-small" onClick={saveNote}>Sisesta</button>
          }
              </div>

      </div>
  );
}

