import React, { useEffect, useState } from 'react';
import { useNavigate, Link, useParams  } from "react-router-dom";
import Cookies from 'js-cookie';


export default function CookieConsent(props) {

  const [accepted, setAccepted] = useState(false);
  const navigate = useNavigate();

  useEffect(()=>{
      if (Cookies.get("coockieconsent")){
        setAccepted(true);
      }
  },[])

  function readmore(){
    console.log("read more");
      navigate("/aboutcookies");
  }
  function accept(){
      Cookies.set("coockieconsent", true);
      setAccepted(true);
  }

  if(accepted)
      return null;
  else
      return(
       
          <div id="cookieconsent">
              <div className="container is-fluid">
                <p>
                  <i className="fa-solid fa-cookie-bite pr-1 fa-lg"></i>
                  Veebileht kasutab küpsiseid. Jätkates veebilehe kasutamist nõustute küpsiste kasutamisega.
                </p>
                <button className="button is-primary is-small" onClick={accept}>Nõustun</button>
                <button className="minimal is-underlined" onClick={readmore}>Loe rohkem</button>
              </div>
          </div>
      );
}

