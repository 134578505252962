import axios from 'axios';
import Cookies from 'js-cookie';
import * as settings from '../settings';


axios.defaults.withCredentials = true;
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";

axios.defaults.baseURL = settings.BASEURL;

function getCarousal() {
         return axios.get("/carousal/");
}


function login(account) {
         return axios.post("/auth/login/", account);
}

function createAccount(account) {
         return axios.post("/accounts/", account);
}
function resendEmailActivation() {
         return axios.get("/resendemailvalidation/");
}
function validateEmail(uuid) {
         return axios.get("/validateemail/"+uuid+"/");
}

function authenticate() {
         return axios.get("/auth/currentaccount/");
}

function logout(account) {
         return axios.post("/auth/logout/");
}
function sendMessage(m) {
         return axios.post("/message/", m);
}
function getDomains() {
         return axios.get("/categories/domains/");
}

function getDomainByPathname(path) {
         return axios.get("/categories/bypath"+path);
}

function getCategory(id) {
         return axios.get("/categories/" + id);
}   

function getSubCategories(id) {
         return axios.get("/categories/parent/" + id + "/");
}   

function getContentItems(id) {
         return axios.get("/content/category/" + id);
}
function getLatest() {
         return axios.get("/content/latest/");
}

function getContentItem(id) {
         return axios.get("/content/" + id + "/");
}
function getItemFile(id) {
         return axios.get("/getfile/" + id + "/", {responseType: 'blob'});
}
function getRandomItem() {
         return axios.get("/content/random/");
}
function getFeatured() {
         return axios.get("/collections/featured/");
}

function getUserCollections() {
         return axios.get("/collections/user-collections/");
}

function addNewCollection(name) {
         return axios.post("/collections/", {name:name});
}
function deleteCollection(coll) {
         return axios.delete("/collections/" + coll.id );
}
function removeItemFromCollection(item, coll) {
         return axios.get("/collections/delete/" + coll.id + '/item/' + item.id);
}

// week
function getCalendar(date) {
    if (!date)
        return axios.get("/calendar/getcalendar");
    else
        return axios.post("/calendar/getcalendarbydate/", {date:date}); 
}
// WEEK
function getCalendarWeek(day) {
    return axios.post("/calendar/week/", {date:day});
}
// WEEK
function getCalendar5Days(day) {
    return axios.post("/calendar/5days/", {date:day});
}
// WEEK
function getCalendarDay(day) {
    return axios.post("/calendar/day/", {date:day});
}

function addItemToCalendar(calitem) {
    return axios.post("/calendar/", calitem);
}
function getDatelessCalendarItems() {
    return axios.get("/calendar/dateless");    
}
function toggleDoneCalendarItem(item) {
    return axios.get("/calendar/" + item.id + "/"+'toggledone');
}
// TODO: BACK-i sellele kontroll, et enda oma kustutad...
function deleteCalendarItem(item) {
    return axios.delete("/calendar/" + item.id);
}
// kui juba liked, siis eemaldab favouritest
function likeItem(item){
        return axios.get("content/like/"+item.id);
}
// kui disliked, siis teeb undislike
function dislikeItem(item){
        return axios.get("content/dislike/"+item.id);
}
function addToCollection(item, coll){
        if(!item || !coll)
                alert("Item või kogumik puudu!");
        return axios.get("collections/add/" + coll.id + "/item/"+item.id);
}

function getDisliked(item){
        return axios.get("content/disliked/");
}

function getFavourites(item){
        return axios.get("content/favourites/");
}
function getUserPreferences(){
        return axios.get("userpreferences/");
}
function combineCollectionItems(col){
        return axios.get("collections/combine/"+col.id);
}
function getUserSubscriptions(){
        return axios.get("subscriptions");
}
function createSubscription(name){
        if (name)
                name += "/";
        else
                name = "";
        return axios.post("subscribe/" + name);
}
function getMonthlySubscriptionProduct(){
        return axios.get("montlysubscription/");
}
function getSubscriptionProduct(name){
        return axios.get("getsubscriptionproduct/"+name+"/");
}
function getTransactionStatus(transaction){
        return axios.post("transactionstatus/", {transaction:transaction});
}
function getAgeGroups(){
        return axios.get("agegroups/");
}

export { 
        login, 
        createAccount, 
        authenticate, 
        logout, 
        getDomains, 
        getDomainByPathname, 
        getCategory, 
        getSubCategories,
        getContentItems,
        getContentItem,
        getRandomItem,
        getFeatured,
        getUserCollections,
        getCalendar,
        getCalendarWeek,
        addItemToCalendar,
        likeItem,
        getFavourites,
        dislikeItem,
        getDisliked,
        getUserPreferences,
        addNewCollection,
        addToCollection,
        deleteCollection,
        removeItemFromCollection,
        combineCollectionItems,
        getItemFile,
        getLatest,
        getCalendar5Days,
        getCalendarDay,
        getDatelessCalendarItems,
        toggleDoneCalendarItem,
        deleteCalendarItem,
        getCarousal,
        getUserSubscriptions,
        createSubscription,
        getMonthlySubscriptionProduct,
        getTransactionStatus,
        resendEmailActivation,
        validateEmail,
        sendMessage,
        getAgeGroups,
        getSubscriptionProduct,
    };




