import React, { useEffect, useState } from 'react';
import { useNavigate, Link, useParams } from "react-router-dom";

export default function Terms() {

  let { topic } = useParams();


  return(
   
      <div id="terms" className="container pt-6 pl-2">
            
          {topic == 'privacy' && 
            <div>
              <h1 className="title is-3">Privaatsuspoliitika</h1>
              <ol>
                <li className="parent">
                        <h1 className="title is-5">Üldsätted</h1>
                    <ol>
                        <li>Käesolev privaatsuspoliitika reguleerib isikuandmete kogumist, töötlemist ja säilitamist käsitlevaid põhimõtteid. Isikuandmeid kogub töötleb ja säilitab isikuandmete vastutav töötleja Pisiworks OÜ (edaspidi andmetöötleja).</li>
                        <li>Andmesubjekt privaatsuspoliitika tähenduses on klient või muu füüsiline isik, kelle isikuandmeid andmetöötleja töötleb.</li>
                        <li>Klient privaatsuspoliitika tähenduses on igaüks, kes ostab andmetöötleja kodulehelt kaupu või teenuseid.</li>
                        <li>Andmetöötleja järgib õigusaktides sätestatud andmete töötlemise põhimõtteid, muuhulgas töötleb andmetöötleja isikuandmeid seaduslikult, õiglaselt ja turvaliselt. Andmetöötleja on võimeline kinnitama, et isikuandmeid on töödeldud vastavalt õigusaktides sätestatule.</li>
                    </ol>
                </li>
                <li className="parent">
                        <h1 className="title is-5">Isikuandmete kogumine, töötlemine ja säilitamine</h1>
                    <ol>
                        <li>Isikuandmed, mida andmetöötleja kogub, töötleb ja säilitab, on kogutud elektrooniliselt, peamiselt kodulehe ja e-posti vahendusel.</li>
                        <li>Oma isikuandmete jagamisega annab andmesubjekt andmetöötlejale õiguse koguda, korraldada, kasutada ja hallata privaatsuspoliitikas määratletud eesmärgil isikuandmeid, mida andmesubjekt otse või kaudselt kodulehel kaupu või teenuseid ostes andmetöötlejale jagab.</li>
                        <li>Andmesubjekt vastutab selle eest, et tema poolt esitatud andmed oleksid täpsed, õiged ja terviklikud. Teadlikult valeandmete esitamist peetakse privaatsuspoliitika rikkumiseks. Andmesubjekt on kohustatud andmetöötlejat viivitamatult teavitama esitatud andmete muutumisest.</li>
                        <li>Andmetöötleja ei vastuta andmesubjekti poolt valeandmete esitamisest põhjustatud kahju eest andmesubjektile või kolmandatele osapooltele.</li>
                    </ol>
                </li>
                <li className="parent">
                        <h1 className="title is-5">Klientide isikuandmete töötlemine</h1>
                    <ol>
                        <li>Andmetöötleja võib töödelda järgnevaid andmesubjekti isikuandmeid:
                            <ol>
                              <li>Ees- ja perekonnanimi</li>
                              <li>Sünnikuupäev</li>
                              <li>Telefoninumber</li>
                              <li>E-posti aadress</li>
                              <li>Kohaletoimetamise aadress</li>
                              <li>Arvelduskonto number</li>
                              <li>Maksekaardi detailid</li>
                            </ol>
                        </li>
                        <li>Lisaks eeltoodule on andmetöötlejal õigus koguda kliendi kohta andmeid, mis on kättesaadavad avalikes registrites.</li>
                        <li>Isikuandmete töötlemise õiguslik alus on isikuandmete kaitse üldmääruse paragrahv 6 lg 1 p-d a), b), c) ja f):
                            <ul className="indexless">
                              <li>a) andmesubjekt on andnud nõusoleku töödelda oma isikuandmeid ühel või mitmel konkreetsel eesmärgil;</li>
                              <li>b) isikuandmete töötlemine on vajalik andmesubjekti osalusel sõlmitud lepingu täitmiseks või lepingu sõlmimisele eelnevate meetmete võtmiseks vastavalt andmesubjekti taotlusele;</li>
                              <li>c) isikuandmete töötlemine on vajalik vastutava töötleja juriidilise kohustuse täitmiseks;</li>
                              <li>f) isikuandmete töötlemine on vajalik vastutava töötleja või kolmanda isiku õigustatud huvi korral, välja arvatud juhul, kui sellise huvi kaaluvad üles andmesubjekti huvid või põhiõigused ja -vabadused, mille nimel tuleb kaitsta isikuandmeid, eriti juhul kui andmesubjekt on laps.</li>
                            </ul>
                        </li>
                        <li>Isikuandmete töötlemine vastavalt töötlemise eesmärgile:
                            <ul>
                              <li>Töötlemise eesmärk – julgeolek ja turvalisus: isikuandmete säilitamise maksimaalne aeg – vastavalt seaduses nimetatud tähtaegadele</li>
                              <li>Töötlemise eesmärk – tellimuse töötlemine: isikuandmete säilitamise maksimaalne aeg – kuni kasutajakonto kustutamiseni </li>
                              <li>Töötlemise eesmärk – e-poe teenuste toimimise tagamine: isikuandmete säilitamise maksimaalne aeg – kuni kasutajakonto kustutamiseni</li>
                              <li>Töötlemise eesmärk – kliendihaldus: isikuandmete säilitamise maksimaalne aeg – kuni kasutajakonto kustutamiseni</li>
                              <li>Töötlemise eesmärk – finantstegevus, raamatupidamine: isikuandmete säilitamise maksimaalne aeg – vastavalt seaduses nimetatud tähtaegadele</li>
                              <li>Töötlemise eesmärk – turundus: isikuandmete säilitamise maksimaalne aeg - kuni kasutajakonto kustutamiseni</li>
                            </ul>
                        </li>
                        <li>Andmetöötlejal on õigus klientide isikuandmeid jagada kolmandate isikutega, kelleks on näiteks volitatud andmetöötlejad, raamatupidajad, transpordi- ja kullerettevõtted, ülekandeteenuseid pakkuvad ettevõtted. Andmetöötleja on isikuandmete vastutav töötleja. Andmetöötleja edastab maksete teostamiseks vajalikud isikuandmed volitatud töötleja Maksekeskus AS-ile.</li>
                        <li>Andmesubjekti isikuandmete töötlemisel ja säilitamisel rakendab andmetöötleja organisatoorseid ja tehnilisi meetmeid, mis tagavad isikuandmete kaitse juhusliku või ebaseadusliku hävitamise, muutmise, avalikustamise ja mis tahes muu ebaseadusliku töötlemise eest.</li>
                        <li>Andmetöötleja säilitab andmesubjektide andmeid sõltuvalt töötlemise eesmärgist, kuni kasutajakonto kustutamiseni.</li>
                    </ol>
                </li>
                <li className="parent">
                        <h1 className="title is-5">Andmesubjekti õigused</h1>
                    <ol>
                        <li>Andmesubjektil on õigus saada ligipääs oma isikuandmetele ning nendega tutvuda.</li>
                        <li>Andmesubjektil on õigus saada informatsiooni tema isikuandmete töötlemise kohta.</li>
                        <li>Andmesubjektil on õigus täiendada või parandada ebatäpseid andmeid.</li>
                        <li>Kui andmetöötleja töötleb andmesubjekti isikuandmeid andmesubjekti nõusoleku alusel, siis on andmesubjektil õigus igal ajal nõusolek tagasi võtta.</li>
                        <li>Andmesubjekt saab õiguste teostamiseks pöörduda e-poe klienditoe poole aadressil kokomini@alatiolemas.ee.</li>
                        <li>Andmesubjektil on oma õiguste kaitseks võimalik esitada kaebus Andmekaitse Inspektsioonile.</li>
                    </ol>
                </li>

                <li className="parent">
                        <h1 className="title is-5">Lõppsätted</h1>
                    <ol>
                        <li>Käesolevad andmekaitsetingimused on koostatud kooskõlas Euroopa Parlamendi ja nõukogu määrusega (EL) nr 2016/679 füüsiliste isikute kaitse kohta isikuandmete töötlemisel ja selliste andmete vaba liikumise ning direktiivi 95/46 / EÜ kehtetuks tunnistamise kohta / EÜ (isikuandmete kaitse üldmäärus), Eesti Vabariigi isikuandmete kaitse seadusega ning Eesti Vabariigi ja Euroopa Liidu õigusaktidega.</li>
                        <li>Andmetöötlejal on õigus andmekaitsetingimusi osaliselt või täielikult muuta, teavitades andmesubjekte muudatustest kodulehe www.kokomini.ee kaudu.</li>
                    </ol>
                </li>



              </ol>
            </div>
          }

         {/* {topic == "sales" && 
          <div>
              <h1 className="title is-3">KOKOmini keskkonnaga liitumise tingimused</h1>
              <ol>
                <li className="parent">
                        <h1 className="title is-5">Üldsätted</h1>
                        <ol>
                        <li>Veebikeskkonna KOKOmini (www.kokomini.ee) (edaspidi KOKOmini) omanik on Pisiworks OÜ (registrikood 14790796), asukohaga Välja 12a, Tõrva, Valgamaa 68606.</li>
                        </ol>
                </li>

                <li className="parent">
                        <h1 className="title is-5">Keskonnaga liitumine ja tellimuse vormistamine</h1>
                    <ol>
                        <li>Keskkonnaga liitumiseks on vajalik esmalt kasutajakonto loomine, seejärel vormistada tellimus.</li>
                        <li>Konto loomisest alates on kasutajale võimaldatud 7-päevane tasuta prooviperiood, mille jooksul on kasutajal ligipääs kogu keskkonna sisule ja funktsionaalsusele võrdväärselt liitunuga.</li>
                        <li>Liitumiseks sõlmitakse kuupõhine püsimakse.</li>
                        <li>Liitumislehel on toodud keskkonnaga liitumise kuutasu suurus.</li>
                        <li>Hinnale ei lisandu ega hind ei sisalda käibemaksu.</li>
                        <li>Tasuda on võimalik Visa/Mastercard kaardimaksega.</li>
                        <li>Tellimuse vormistamiseks tuleb vajutada nuppu "Liitu", seejärel sisestada vajalikud kaardiandmed ning märkida, et nõustutakse liitumistingimustega ning püsimakse sõlmimisega.</li>
                        <li>Esimene makse teostatakse kohe pärast kaardiandmete sisestamist ning maksmise kinnitamist.</li>
                        <li>Järgmistel kuudel teostatakse liitumisega samal kuupäeval kuutasu makse automaatselt.</li>
                        <li>Makseid vahendab Maksekeskus AS. Tasumine toimub väljaspool KOKOmini veebilehte Maksekeskus AS 
                        turvalises keskkonnas. Müüjal puudub ligipääs kliendi panga ja krediitkaardi andmetele.</li>
                        <li>Leping jõustub alates tasumisele kuuluva summa laekumisest KOKOmini omaniku arvelduskontole.</li>
                        <li>KOKOmini omanik on isikuandmete vastutav töötleja ning edastab maksete teostamiseks vajalikud isikuandmed volitatud töötleja Maksekeskus AS-le.</li>
                    </ol>
                </li>


                <li className="parent">
                        <h1 className="title is-5">Keskkonna liitumise ja kasutamise tingimused</h1>
                    <ol>
                        <li>KOKOmini keskkonda võib kasutada isiklikuks tarbeks kasutaja tema ja tema leibkonna liikmete poolt.</li>
                        <li>KOKOmini keskkonna kasutamine juriidilise isiku poolt või füüsilise isiku poolt majandustegevuse raames on lubatud KOKOmini omaniku kirjalikku taasesitamist võimaldaval nõusolekul.</li>
                        <li>Kasutaja konto võib korraga olla sisselogitud 3 erinevas seadmes.</li>
                        <li>KOKOmini keskkonna sisu jagamine elektroonilisel kujul kolmandatele isikutele (v.a kasutaja leibkonna liikmetele) ei ole lubatud.</li>
                    </ol>
                </li>


                <li className="parent">
                        <h1 className="title is-5">Lepingu lõpetamine ja taganemisõigus</h1>
                    <ol>
                        <li>KOKOmini keskkonnaga liitumisele ei kohaldu sidevahendi abil sõlmitud lepingutele kohalduv taganemisõigus (VÕS § 53 lg 4 punkt 7<sup>1</sup>)</li>
                        <li>Kasutaja võib igal hetkel lõpetada püsitellimuse kirjutades vastavast soovist e-maili aadressile kokomini@alatiolemas.ee. Et uut automaatset makset ei tehtaks,
                        tuleb vastav soov esitada vähemalt 3 päeva enne järgmise kuumakse teostamise kuupäeva.</li>
                        <li>Püsitellimuse lõpetamisel saab kasutaja keskkonda kasutada hetkel kehtiva tellimuse lõppemiseni (kuupäev nähtav kasutajakonto lehel).</li>
                        <li>Püsitellimuse lõpetamisel on kasutajakonto lehel nähtav tellimuse staatus "Lõpetatud" ning järgneval kuul uut automaatset makset ei teostata.</li>
                        <li>KOKOminil on õigus liitumisleping ühepoolselt lõpetada ilma etteteatamistähtajata kasutajapoolse keskkonna liitumise ja kasutamise tingimuste rikkumise korral või muul juhul, kui
                        kasutaja tegevus tekitab KOKOmini keskkonnale või omanikule kahju.</li>
                        
                    </ol>
                </li>
                    <li className="parent">
                        <h1 className="title is-5">Otseturundus ja isikuandmete töötlemine</h1>
                    <ol>
                        <li>KOKOmini kasutab ostja poolt sisestatud isikuandmeid ainult tellimuse töötlemiseks ning kauba ostjale saatmiseks. KOKOmini edastab isikuandmeid veoteenust pakkuvale ettevõtetele selleks, et kohale toimetada kaupa.</li>
                        <li>KOKOmini saadab ostjale uudiskirju ning pakkumisi ostja e-maili aadressile ainult juhul, kui ostja on selleks avaldanud soovi sisestades veebilehel e-kirja aadressi ning andnud teada oma soovist otsepostituse teadete saamiseks.</li>
                        <li>Ostjal on igal ajal võimalik loobuda e-postile saadetavatest pakkumistest ja uudiskirjadest andes sellest meile teada e-kirja teel või järgides pakkumisi sisaldava e-kirjas toodud juhiseid.</li>
                    </ol>
                </li>

                   <li className="parent">
                        <h1 className="title is-5">Vaidluste lahendamine</h1>
                    <ol>
                        <li>Kui ostjal on KOKOmini keskkonna osas pretensioone, tuleb need saata e-kirja aadressile kokomini@alatiolemas.ee</li>
                        <li>Kui ostja ja KOKOmini ei suuda lahendada vaidlust kokkuleppe teel, siis on ostjal võimalik pöörduda Tarbijavaidluste komisjoni poole.</li>
                    </ol>
                </li>


              </ol>
          </div>



        }*/}

      </div>
  );
}

