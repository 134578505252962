import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";

export default function Breadcrumbs(props) {

  const [breadcrumbs, setBreadcrumbs] = useState({});

  useEffect(()=>{
    // set breadcrumbs
    if (props.domain){
        var br = {"home": {path:'/', name:'Esileht'}};
        if (props.domain)
            br['domain'] = props.domain;
        if (props.category){
            br['category'] = props.category;
        }
        if (props.contentItem){
            br['item'] = props.contentItem;
        }
        setBreadcrumbs(br);
    }

  }, [props.domain, props.category]);


  return(
    <div className="container">
        <nav className="breadcrumb" aria-label="breadcrumbs">
          <ul>
            <li>
                {breadcrumbs['home'] && <Link to={'/'}> {breadcrumbs['home'].name} </Link> }
            </li>
            <li>
                {breadcrumbs['domain'] && <Link to={'/domain/'+breadcrumbs['domain'].id}> {breadcrumbs['domain'].name} </Link> }
            </li>   
                <li>
                    {breadcrumbs['category'] && <Link to={'/domain/'+breadcrumbs['domain'].id+'/category/'+breadcrumbs['category'].id} > {breadcrumbs['category'].name} </Link> }
                </li>
                <li>
                    {breadcrumbs['item'] && <Link to={'/domain/'+breadcrumbs['domain'].id+'/item/'+breadcrumbs['item'].id} > {breadcrumbs['item'].name} </Link> }
                </li>

          </ul>
        </nav>

    </div>

  );
}



