import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { createAccount, logout } from './API';
import Tooltip from '@mui/material/Tooltip';

export default function Register(props) {

    const navigate = useNavigate();
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const onSubmit = data => registering(data);
    const [registererror, setRegistererror] = useState("");

    const password = watch("password", "");

    function registering(account){
        setRegistererror("");
        createAccount(account).then(
              (res) => {
                // props.setLoggedIn(true);
                // props.setAccount(res.data);
                window.location.href = "/dashboard/account";
              },
              (err) => {
                if (err.response.data.message) {
                  var message = err.response.data.message;
                  if(message.email && message.email.length > 0) {
                    setRegistererror(message.email[0]);
                  }
                }
              }
            );
    }

    function loggingOut(){
        logout().then(()=>{
          props.setLoggedIn(false);
        });
    }

    function onEmailChange(){
      setRegistererror('');
    }

    if(props.loggedIn)
      return (
              <div className="container p-3 has-text-centered">
              <h1 className="title is-4">Konto loomine</h1>
                  <p className="m-2">Olete sisselogitud. Enne uue konto loomist tuleb välja logida. Kas soovite seda teha?</p>
                    <button className="button mt-3 is-light is-small mr-2" onClick={()=>{navigate(-1)}}>Tagasi</button>
                    <button className="button mt-3 is-danger is-light is-small" onClick={loggingOut}>Logi välja</button>
                  <p className="mt-4"><Link to="/">Esilehele</Link></p>
              </div>
        )
    else

  return(
  

   <div className="is-purple">
          <div id="registerpage" className="container has-text-centered">

            <div className="form-container">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="formdiv">
                        <p className=""><Link to="/login">Kasutaja olemas?</Link></p>

                        <h1 className="title">Konto loomine</h1>

                        <div className="field">
                            <label className="label">Eesnimi:</label>
                            <div className="control has-icons-left">
                              <input className={'input ' + (errors.name && 'is-danger')}  type="textarea" placeholder="Nimi"  {...register("name", { required: true })} />
                        
                              <span className="icon is-small is-left">
                                <i className="fa-solid fa-user"></i>
                              </span>
                            </div>
                          </div>

                                                  <div className="field">
                            <label className="label">Perekonnanimi:</label>
                            <div className="control has-icons-left">
                              <input className={'input ' + (errors.name && 'is-danger')}  type="textarea" placeholder="Nimi"  {...register("surname", { required: true })} />
                        
                              <span className="icon is-small is-left">
                                <i className="fa-solid fa-user"></i>
                              </span>
                            </div>
                          </div>

                        <div className="field">
                            <label className="label">Email</label>
                            <div className="control has-icons-left">
                              <input className={'input ' + (errors.email && 'is-danger')} onChange={onEmailChange} type="email" placeholder="E-mail"  {...register("email", { required: true })} />
                        
                              <span className="icon is-small is-left">
                                <i className="fas fa-envelope"></i>
                              </span>
                            </div>
                          </div>

                          <div className="field">
                            <Tooltip title="Salasõna peab olema vähemalt 8 tähemärki pikk">
                            <label className="label">Salasõna 
                                <i className="ml-2 fa-solid fa-circle-info"></i>
                            </label>
                            </Tooltip>
                            <div className="control has-icons-left">
                              <input className={'input ' + (errors.password && 'is-danger')} type="password" placeholder="Salasõna" {...register("password", { required: true })} />
                              <span className="icon is-small is-left">
                                <i className="fas fa-regular fa-key"></i>
                              </span>
                            </div>
                          </div>

                          <div className="field">
                            <label className="label">Korda salasõna</label>
                            <div className="control has-icons-left">
                              <input className={'input ' + (errors.confirm_password && 'is-danger')} type="password" placeholder="Salasõna" 
                                        // {...register("confirm_password", { required: true })} 
                                                {... register( 'confirm_password', {
                                                    validate: value =>
                                                      value === password || "Salasõnad ei ole identsed"
                                                  })}
                              />
                              {errors.confirm_password &&   <p className="tag is-danger">{errors.confirm_password.message}</p>}

                              <span className="icon is-small is-left">
                                <i className="fas fa-regular fa-key"></i>
                              </span>
                            </div>
                          </div>

                          <div className="field">
                              <button className="button is-primary" type="submit" disabled={password.length<8}>Sisesta</button>
                          </div>
                         { registererror && <div className="field notification is-warning"> Tekkis viga konto loomisel. <br/> {registererror} </div>  } 
                         {/*{ errors.confirm_password && <div className="field notification is-warning"> Viga andmede sisestamisel! {errors.password} {errors.name}  {errors.email} {errors.confirm_password} </div>  } */}

                         </div>
                  </form>
            </div>
          </div>
      </div>
  );
}

