import React, { useEffect, useState } from 'react';
import { BrowserRouter, Router, Routes, Route, Navigate, useParams } from 'react-router-dom';
import Dashboard from './Dashboard';



export default function DashboardRoute(props) {
  
  useEffect(()=>{


  }, []);


  return(
   
      <div>
          <Routes>
              <Route index element={<Dashboard subscribed={props.subscribed} account={props.account} loggedIn={props.loggedIn} />} />
              <Route path=":route" element={<Dashboard subscribed={props.subscribed} account={props.account} loggedIn={props.loggedIn} />} />
              <Route path=":route/:id" element={<Dashboard subscribed={props.subscribed} account={props.account} loggedIn={props.loggedIn} />} />
          </Routes>
      </div>
  );
}

