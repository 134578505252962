import React, { useEffect, useState } from 'react';
import { useNavigate, Link, useParams } from "react-router-dom";
import { sendMessage } from './API';

export default function Contact(props) {

  let { route } = useParams();


  const [active, setActive] = useState(0);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState();

  let text = "KOKOmini taga on 4-liikmeline perekond...";

  useEffect(()=>{
  },[props.account])

  function onTitleChange(e){
      setTitle(e.target.value);
  }
  function onContentChange(e){
      setContent(e.target.value);
  }  
  function onEmailChange(e){
      setEmail(e.target.value);
  }
  function onSubmit(e){
      console.log(e);
      setContent("");
      setTitle("");
      e.preventDefault();

  }
  function send(){
    let email_addr = email;
    if (props.account)
        email_addr = props.account.email
    sendMessage({title:title, content:content, email:email_addr}).then(
          (res)=>{
              setStatus('success');
          },
          ()=>{
              setStatus('failed')
          }
        )
  }
  return(
   

      <div id="contact" className="container mt-6">
          <div className={"flexbox flex-row wrapping-flex" }>
              <div className="flexcol">
                  <h1 className="title is-4">KOKOminist</h1>
                  {/*<p>KOKOmini pere on ka väljaspool internetti perekond. Meil on kasvamas kaks rõõmsat tüdrukut, kes, nagu lapsed ikka, naudivad uute asjade avastamist, uurimist ja muidugi mängimist.</p>*/}
                  <p>KOKOmini keskkonnas leiduvad mängud, töölehed ja jutud on sageli tehtud lastega koos ning nendega koos ka läbi testitud. (Rääkides testimisest, siis oh need kirjavead - need on ikkagi suurte hooletus, mitte laste :))</p>
                  <p>KOKOmini nimi on tuletatud sõnadest "kodu", "kool" ja "mini" ehk kodused õppevahendid kõige pisematele.</p>
                  <p>Meie südames on pühendumus pakkuda lastele lõbusaid tegevusi, mille käigus nad ka märkamatult õpivad. Rõõm on näha lapsi mängimas, aga eks igal lapsevanemal näpistab sees ka väike mure ja soov, et lapsed ikka targad ja nutikad oleksid. Seetõttu püüamegi õppimist ja mängu ühendada.</p>
                  <p>Oleme tänulikud teie toetuse eest ning kui teil on mingeid küsimusi või ettepanekuid, siis ärge kartke meiega ühendust võtta.</p>

              </div>
              <div className={"flexcol " + (route=='contactus'?'is-first':'')}>
                    <h1 className="title is-4 m4-4 has-text-centered">Kirjuta meile!</h1>
                    <div className="container is-beige p-4">


                  <form onSubmit={onSubmit}>
                      {!props.loggedIn && 
                      <div>
                          <p className="mb-2 mt-2">E-maili aadress:</p>
                          <div className="ml-5">
                            <input type="email" className="input" value={email} onChange={onEmailChange} />
                          </div>
                      </div>
                      }
                      <p className="mb-2 mt-2">Pealkiri:</p>
                      <div className="ml-5">
                        <input type="text" className="input" value={title} onChange={onTitleChange} />
                      </div>
                      <p className="mb-2 mt-2">Sõnum:</p>
                      <div className="ml-5">
                        <textarea type="text" className="textarea" value={content} onChange={onContentChange}></textarea>
                      </div>
                      <div className="has-text-centered">
                          <button className="button is-primary m-4" onClick={send}>Saada</button>
                      </div>
                    </form>
                      {status && status == 'success' &&
                          <p className="has-text-centered notification is-success">Täname kirja eest!</p>
                      }
                      {
                        status && status != 'success' &&
                          <p className="has-text-centered notification is-warning">Midagi läks valesti... Proovid ehk uuesti? <br/>Kui ikka ei õnnestu, siis kirjuta e-mail (info@kokomini.ee)</p>
                      }
                </div>
            </div>
        </div>
      </div>
  );
}

