import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, Link, useParams } from 'react-router-dom'

import ItemsList from './ItemsList';
import { getDisliked } from './API';

// props: subscribed
export default function Disliked(props) {

  const [items, setItems] = useState();


  useEffect(()=>{
      if(props.account){
          getDisliked().then((res)=>{
              setItems(res.data);
          });
      }
  }, [props.account])

  function removeItem(item){
      setItems(items.filter((e)=>{return e.id != item.id}));
  }

    return (
            
            <ItemsList subscribed={props.subscribed} showDisliked={true} title={"Peidetud"} preferences={props.preferences} setPreferences={props.setPreferences} items={items} setItems={setItems} loggedIn={props.loggedIn} disliked={true}/>
               
    )

}