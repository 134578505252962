import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, Link, useParams } from 'react-router-dom'
import Tooltip from '@mui/material/Tooltip';

import { getCalendar, getCalendar5Days, getCalendarDay, toggleDoneCalendarItem, addItemToCalendar, deleteCalendarItem } from './API';

export default function Calendar(props) {

  const [allItems, setAllItems] = useState();
  const [weekdays, setWeekdays] = useState([
          {name:'Esmaspäev', short:'E'},
          {name:'Teisipäev', short:'T'},
          {name:'Kolmapäev', short:'K'},
          {name:'Neljapäev', short:'N'},
          {name:'Reede', short:'R'},
          {name:'Laupäev', short:'L'},
          {name:'Pühapäev', short:'P'},
      ]);
  const [week, setWeek] = useState();
  const [current, setCurrent] = useState(getJustDate(new Date()));
  const [today, setToday] = useState(getJustDate(new Date()));
  const [currentDateTime, setCurrentDateTime] = useState(getJustDate(new Date()).getTime());

  const [newNote, setNewNote] = useState("");
  const [showAdd, setShowAdd] = useState();

  const [update, setUpdate] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(()=>{
      getCalendarItems();

  },[props.update, update])

  function getCalendarItems(date){
      if (!date){
        date = current;
      }
      console.log(date);
      getCalendar5Days(getDateString(date)).then((res)=>{
            setAllItems(res.data);
            organiseWeek(res.data, date);
            console.log(res.data);
          });
  }

  // set 5 days to week
  function organiseWeek(itemslist, from){
      var w = Array();
      for (let i = -2; i<3; i++){
          var d = {
            day: getDay(i, from),
            items: getItems(itemslist, i, from),
          };
          w.push(d);
      }
      setWeek(w);
      setLoading(false);
  }
  function getDay(index, from){
      // from weekdays compared to current
      if (!from)
          from = today;
      var date = new Date(from);
      date.setDate(date.getDate()+index);
      var wd = date.getDay() == 0 ? 6 : date.getDay()-1;
      var day = weekdays[wd];
      day.date = date;
      day.from = index == 0;
      day.today = day.date.getTime()==today.getTime();
      return day;
  }
  function getItems(list, index, from){
      if (!from)
        from = today;
      var day = (new Date(from)).setDate(from.getDate()+index);
      var items = list.filter((e)=>{return getDateTime(new Date(e.date)) == day });
      return items;
  }

  function getDateTime(date){
    var d = getJustDate(new Date(date));
    return d.getTime();
  }
    function getDateString(date){
      var d = (new Date(date.getFullYear(), date.getMonth(), date.getDate()));
      var month = d.getMonth() < 10 ? "0" + (d.getMonth()+1) : d.getMonth()+1;
      var day = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
    return d.getFullYear() + month  + day;
  }

  function getJustDate(d){
      return new Date(d.getFullYear(), d.getMonth(), d.getDate());
  }
  function doUpdate(){
    setUpdate(update+1);
  }
  function clickOnDay(day){
      setLoading(true);
      setTimeout(()=>{
        setCurrent(day.day.date);
        getCalendarItems(day.day.date);
      }
      ,0);
  }

  function markDone(event, item){
        event.stopPropagation();
        item.done = !item.done;
          if(!item.item){
            item.item = {};
          }
          toggleDoneCalendarItem(item).then((res)=>{
            getCalendarItems();
          });
  }

  function onNoteChange(event){
      setNewNote(event.target.value);
      setShowAdd(event.target.value.length > 0);
  }
  function saveNote(){
        var date_str = current.getFullYear().toString() + '-' + (current.getMonth()+1).toString() + '-' + current.getDate().toString();
        addItemToCalendar({item:{},content:newNote, itemType:'note', date:date_str}).then((res)=>{
        getCalendarItems(current);
        setNewNote("");
        setShowAdd(false);

    });
  }
  function onKeyDown(event){
    if (event.key === 'Enter') {
      saveNote();
    }
  }
    function deleteNote(e, item){
      deleteCalendarItem(item).then(()=>{
        getCalendarItems();
      });
      e.stopPropagation();
  }

    return (

        <div id="calendar5" className="mb-3">
          <h1 className="title is-5">Kalender</h1>
            <p className="mb-2"><button disabled={current.getTime()==today.getTime()} onClick={()=>{getCalendarItems(getJustDate(new Date()))}} className="button is-small">Täna</button></p>

            <div className={"week columns " + (loading ? 'loading':'') }>
                {week && week.map((d,i)=>
                    <div 
                        key={i} 
                        className={"column day clickable " + (i==2?'current ':' ') + (i==1 || i==3? 'second ':' ') + (i==0 || i==4 ? 'third ':' ')+(d.day && d.day.today ? 'today':'')}
                        onClick={()=>{clickOnDay(d)}} 
                    >
                      <div className="daycontent">
                        <p className="has-text-centered">{d.day && d.day.current ? d.day.name : d.day.short} {d.day && d.day.today && <span className="tag is-warning is-pulled-right">Täna</span>}</p>
                        <p className="has-text-centered date">{d.day.date.getDate()}.{d.day.date.getMonth()<10?'0':''}{d.day.date.getMonth()+1}</p> 
                        <hr/>
                        {d.items && d.items.map((item)=>
                            <div key={item.id}>
                                {!item.item && 
                                    <div className={item.done ? 'done':''}>
                                      {i==2 &&
                                        <Tooltip title={item.done?"Tegemata":"Märgi tehtuks"}>
                                            <i className="mr-1 fa-solid fa-check clickable" onClick={(e)=>{markDone(e, item)}}></i>
                                        </Tooltip>
                                      }
                                        {item.name} {item.content}
                                      {i==2 &&
                                        <Tooltip title="Kustuta">
                                              <i className="ml-3 fa-solid fa-xmark clickable" onClick={(e)=>{deleteNote(e, item)}}></i>
                                          </Tooltip>
                                      }
                                    </div>
                                }
                                {item.item &&
                                  <div className={'item ' + (item.done ? 'done':'')}>
                                      <div className={"boxed " + (item.item.domain==1?'is-purple ':'') + (item.item.domain==2?'is-orange ':'') + (item.item.domain==3?'is-blue ':'')}>
                                          {i==2 &&
                                            <Tooltip title={item.done?"Tegemata":"Märgi tehtuks"}>
                                                <i className="ml-1 fa-solid fa-check clickable" onClick={(e)=>{markDone(e, item)}}></i>
                                            </Tooltip>
                                          }
                                          <Link to={'/domain/'+item.item.domain + '/item/' + item.item.id}>{item.item.name}</Link>
                                          {i==2 &&
                                            <Tooltip title={"Kustuta"}>
                                                <i className="ml-3 fa-solid fa-xmark clickable" onClick={(e)=>{deleteNote(e, item)}}></i>
                                            </Tooltip>
                                          }
                                      </div>
                                  </div>
                                }
                            </div>
                        )}
                     
                        {2 == i && 
                            <div>
                              <input onClick={(e)=>{e.stopPropagation();}} placeholder="...lisa" type="text" className="input noteinput mt-2 mb-2" value={newNote} onChange={onNoteChange} onKeyDown={onKeyDown}/>
                              {showAdd && 
                                    <button className="button is-primary is-light is-small" onClick={saveNote}>Sisesta</button>
                              }
                            </div>
                        }
                        </div>
                      </div>
                  )}
            </div>
            <p className="has-text-centered mt-2"><button className="button is-primary is-small is-light" onClick={()=>{props.showCalendar(true)}}>Nädala vaade</button></p>

        </div>
               
    )

}