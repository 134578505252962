import React, { useEffect, useState } from 'react';
import { useLocation, Link, useParams } from 'react-router-dom';
import { getUserCollections } from './API';

// props.collectionId
export default function AccountSidebar(props) {


  const [collections, setCollections] = useState();

  useEffect(()=>{
    if (props.loggedIn) {
            getUserCollections().then((res)=>{
            setCollections(res.data.filter((e)=>{return e.collectionType != 'DISLIKED'}));
        });
    }
  },[]);

  if (!props.loggedIn)
        return null
else 
      return(
       
      <div id="collections-sidebar" className="menu">
            <ul className="menu-list">
            <p className="menu-label"><span className="title is-6">Kogumikud:</span></p>
                {collections && collections.map((object, i) => 

                    <li key={object.id}>
                        <Link to={'/dashboard/collections/' + object.id} className={(props.collectionId == object.id) ? 'is-active' : ''} > 
                                        {object.name}
                        </Link> 
                    </li>
                )}
            </ul>
          </div>
      );
}

