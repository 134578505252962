import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from "react-router-dom";
import { useForm } from "react-hook-form";

import { login, logout } from './API';

export default function Login(props) {


    const navigate = useNavigate();
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const onSubmit = data => loggingin(data);
    const [loginerror, setLoginerror] = useState("");

    function loggingin(account){
        login(account).then(
              (res) => {
                console.log(res);
                props.setAccount(res.data);
                props.setLoggedIn(true);
                navigate("/dashboard");
              },
              (error) => {
                setLoginerror(error.response.data.message);
              }
            );
    }

        function loggingOut(){
        logout().then(()=>{
          props.setLoggedIn(false);
        });
    }

    if(props.loggedIn)
      return (
              <div className="container p-3 has-text-centered">
              <h1 className="title is-4">Logi sisse</h1>
                  <p className="m-2">Olete sisselogitud. Teise kontoga sisselogimiseks tuleb välja logida. Kas soovite välja logida?</p>
                    <button className="button mt-3 is-light is-small mr-2" onClick={()=>{navigate(-1)}}>Tagasi</button>
                    <button className="button mt-3 is-danger is-light is-small" onClick={loggingOut}>Logi välja</button>
                  <p className="mt-4"><Link to="/">Esilehele</Link></p>
              </div>
        )
    else

  return(
   
   <div className="is-purple">
          <div id="loginpage" className="container has-text-centered">
            <div className="form-container">
                      <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="formdiv">
                          
                          <p><Link to="/register">Pole veel kasutajat?</Link></p>

                          <h1 className="title">Logi sisse</h1>
                          <div className="field">
                              <label className="label">Email</label>
                              <div className="control has-icons-left">
                                <input className={'input ' + (errors.email && 'is-danger')}  type="email" placeholder="E-mail"  {...register("email", { required: true })} />
                          
                                <span className="icon is-small is-left">
                                  <i className="fas fa-envelope"></i>
                                </span>
                              </div>
                            </div>

                            <div className="field">
                              <label className="label">Salasõna</label>
                              <div className="control has-icons-left">
                                <input className={'input ' + (errors.password && 'is-danger')} type="password" placeholder="Salasõna" {...register("password", { required: true })} />
                                <span className="icon is-small is-left">
                                  <i className="fas fa-regular fa-key"></i>
                                </span>
                              </div>
                            </div>

                            <div className="field">
                                <button className="button is-primary" type="submit">Sisesta</button>
                            </div>
                           { loginerror && <div className="field notification is-warning"> Viga sisselogimisel. Proovige uuesti! </div>  } 
                      </div>
                    </form>

            </div>
          </div>
      </div>
  );
}

