import React, { useEffect, useState } from 'react';
import { useLocation, Link, useParams } from 'react-router-dom';
import Collections from './Collections';

// Kogumiku jaoks teha page...
// ehk siis kogumike sidebar tõuseb üles poole... ja contentlist tuleb sarnane paremale, 
// filtreerimisvõimalusega... aga siis kogumiku itemite põhjkal

export default function CollectionsPage(props) {

  let { collectionId } = useParams();


  return(
   
   // id ära vahetada
      <div id="collectionspage">
          <div className="container is-fluid">
            <Collections subscribed={props.subscribed} />
          </div>
      </div>
  );
}

