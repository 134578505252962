import React, { useEffect, useState } from 'react';
import { useNavigate, redirect } from "react-router-dom";

export default function AboutCookies(props) {

  const navigate = useNavigate();

  useEffect(()=>{

  },[])

  return(
   
      <div id="aboutcookies" className="container pt-6 pl-2">
          <h1 className="title is-3 mb-5">Küpsistest</h1>
          <h1 className="title is-5">Mis on küpsised?</h1>
          <p className="ml-5 mb-3">Küpsis veebilehel on väike tekstifail, mis paigaldatakse veebilehe külastaja arvutisse. </p>
          <h1 className="title is-5">Milleks küpsised vajalikud on?</h1>
          <p className="ml-5 mb-3">Küpsised võimaldavad veebilehel salvestada infot konkreetse kasutaja kohta, et kuvada just sellele kasutajale sobivat sisu. 
          Lisaks kasutatakse küpsiseid lehe külastuste analüüsimiseks.</p>
           <h1 className="title is-5">Missuguseid küpsiseid KOKOmini kasutab?</h1>
          <p className="ml-5 mb-3">Meie veebileht kasutab mõningaid küpsiseid kasutaja eeliste salvestamiseks (näiteks nendesamade küpsistega nõustumise meelespidamiseks salvestatakse vastav küpsis,
            et veebileht järgmisel korral jälle nõustumist ei küsiks), sisselogimise meelespidamiseks (sessiooni ID) ning pettuste ärahoidmiseks (csrftoken). Lisaks salvestatakse ka küpsised järgnevate kolmandate osapoolte poolt, kelle funktsionaalsust oma lehel kasutame: Maksekeskus AS (makseprotsessiga seonduvaks), Google Analytics (veebilehel külastatavuse statistika jaoks), Font Awesome (kelle vahvaid ikoone kasutame).</p>
          <h1 className="title is-5">Mulle ei meeldi küpsised!</h1>
          <p className="ml-5 mb-3"> Küpsiste salvestamise seadeid saate muuta oma veebilehitseja seadete alt, muuhulgas keelata küpsiste salvestamise teie arvutisse. Uurige lähemalt oma veebilehitseja kodulehelt.</p>
          <p className="has-text-centered mt-6"><button className="button is-primary is-small is-light" onClick={()=>{console.log(navigate);navigate(-1)}}> Tagasi </button></p>
      </div>
  );
}

