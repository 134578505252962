import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

import Featured from './Featured';
import Calendar from './Calendar';
import CalendarSixDays from './CalendarSixDays';
import Favourites from './Favourites';
import Disliked from './Disliked';
import Account from './Account';
import ItemsList from './ItemsList';
import Collections from './Collections';
import Subscribe from './Subscribe';
import Todo from './Todo';

import { getUserPreferences, getLatest } from './API';


// props: loggedIn, subscribed, account
export default function Dashboard(props) {

  const navigate = useNavigate();
  const [reload, setReload] = useState(0);
  const [showCalendar, setShowCalendar] = useState();
  const [preferences, setPreferences] = useState({hidden:[], liked:[], done:[]});
  const [collections, setCollections] = useState(0);
  const [latest, setLatest] = useState();
  let { route, id } = useParams();

  const [doupdate, setUpdate] = useState(0);

  function update(){
    setUpdate(doupdate+1);
  }


  useEffect(()=>{
        getLatest().then((res)=>{
            setLatest(res.data);
        });
        if(Cookies.get('showcalendar')){
            setShowCalendar(Cookies.get('showcalendar')=='true');
        }
  },[]);

  useEffect(()=>{
        if (props.loggedIn){
            getUserPreferences().then((res)=>{
                setPreferences(res.data);
            });
        }
  },[props.loggedIn]);

  function pleaseShowCalendar(bool){
        Cookies.set('showcalendar',bool.toString());
        setShowCalendar(bool);
  }

    if (!props.loggedIn) 
        return (
              <div className="container mt-6 p-3 has-text-centered">
                  <h1 className="title is-4">Töölaud on ligipääsetav sisselogitud kasutajatele</h1>
                      <p className="mt-"></p>
                        <button className="button mr-2 is-light is-small mr-2" onClick={()=>{navigate(-1)}}>Tagasi</button>
                        <Link to="/login"><button className="button mr-2 is-danger is-light is-small">Logi sisse</button></Link>
                        <Link to="/register"><button className="button mr-2 is-danger is-light is-small">Registreeru</button></Link>
                      <p className="mt-4"><Link to="/">Esilehele</Link></p>
              </div>
          )

    else

    return (
      <div id="dashboard" className="container is-fluid pt-5 pb-2">

            <div className="columns">

                <nav className="column menu is-one-fifth">
                    <div className="menu">
                        <button className="button is-info is-light"><Link to="/dashboard">Töölaud</Link></button>
                    <p className="menu-label">Menüü:</p>

                    <ul className="menu-list">
                      <li><Link to="/dashboard/calendar">Kalender</Link></li>
                      <li><Link to="/dashboard/collections">Kogumikud</Link></li>
                      <li><Link to="/dashboard/favourites">Lemmikud</Link></li>
                      <li><Link to="/dashboard/hidden">Peidetud</Link></li>
                      <hr/>
                      <li><Link to="/dashboard/account">Konto andmed</Link></li>
                    </ul>
                    </div>  
                </nav>

                <div className="column is-four-fifths">
                    {!route &&
                        <div>
                        {!showCalendar  &&
                            <div className="columns">
                                <div className="column">
                                    <CalendarSixDays showCalendar={pleaseShowCalendar} update={doupdate} preferences={preferences} setPreferences={setPreferences} account={props.account} loggedIn={props.loggedIn}/>
                                </div>
                                 <div className="column">
                                    <Todo update={doupdate} preferences={preferences} setPreferences={setPreferences} account={props.account} loggedIn={props.loggedIn}/>
                                </div>
                            </div>
                        }

                        {showCalendar &&
                            <div>
                                <Calendar showCalendar={pleaseShowCalendar} update={doupdate} preferences={preferences} setPreferences={setPreferences} account={props.account} loggedIn={props.loggedIn}/>
                                <Todo update={doupdate} preferences={preferences} setPreferences={setPreferences} account={props.account} loggedIn={props.loggedIn}/>
                            
                            </div>
                        }

                            
                            <ItemsList subscribed={props.subscribed} update={update} title="Uued" x-isSmall={true} preferences={preferences} setPreferences={setPreferences} items={latest} setItems={setLatest} loggedIn={props.loggedIn}/>
                        </div>
                    }
                    {!route || route == 'calendar' &&
                        <div>
                        {!showCalendar  &&
                            <div className="columns">
                                <div className="column">
                                    <CalendarSixDays showCalendar={pleaseShowCalendar} update={doupdate} preferences={preferences} setPreferences={setPreferences} account={props.account} loggedIn={props.loggedIn}/>
                                </div>
                                 <div className="column">
                                    <Todo update={doupdate} preferences={preferences} setPreferences={setPreferences} account={props.account} loggedIn={props.loggedIn}/>
                                </div>
                            </div>
                        }

                        {showCalendar &&
                            <div>
                                <Calendar showCalendar={pleaseShowCalendar} update={doupdate} preferences={preferences} setPreferences={setPreferences} account={props.account} loggedIn={props.loggedIn}/>
                                <Todo update={doupdate} preferences={preferences} setPreferences={setPreferences} account={props.account} loggedIn={props.loggedIn}/>
                            
                            </div>
                        }
                        </div>
                    }
                    {! route &&
                      <div>
                        {/*<Featured preferences={preferences} setPreferences={setPreferences} account={props.account} loggedIn={props.loggedIn}/>*/}
                        {/*<hr />*/}
                      </div>
                    }
                    {route == 'favourites' &&
                        <Favourites subscribed={props.subscribed} preferences={preferences} setPreferences={setPreferences} loggedIn={props.loggedIn}/>
                    }

                    {route == 'hidden' &&
                        <Disliked subscribed={props.subscribed} preferences={preferences} setPreferences={setPreferences} account={props.account} loggedIn={props.loggedIn}/>
                    }
                    {route == 'account' &&
                        <Account subscribed={props.subscribed} account={props.account} loggedIn={props.loggedIn}/>
                    }
                    {route == 'collections' &&
                        <Collections subscribed={props.subscribed} preferences={preferences} setPreferences={setPreferences} account={props.account} loggedIn={props.loggedIn} setCollections={setCollections} collectionId={route=='collections'?id:null}/>
                    }
                    {route == 'subscribe' &&
                        <Subscribe subscribed={props.subscribed} account={props.account} loggedIn={props.loggedIn}/>
                    }


                    </div>  
            </div>

      </div>
    );
}