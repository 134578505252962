import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { getSubCategories } from './API';

export default function Categories(props) {

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth<=768);
  const [showCategories, setShowCategories] = useState(true);

// Get categories from API
  useEffect(() => {
    // get all domain categories for sidebar
    if (props.domain){
        getSubCategories(props.domain.id).then(onSuccess, onFailure);
      }
  }, [props.domain])


  useEffect(()=>{
        if (isMobile) {
            setShowCategories(false);
        }
  },[props.category, props.domain, isMobile])

  useEffect(()=>{
        if (props.isItemPage) {
            setShowCategories(false);
        }
  }, [props.isItemPage])

    function handleWindowSizeChange() {
        setIsMobile(window.innerWidth<=768);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);


  function onSuccess(res){
      setIsLoaded(true);
      if (res.status == 204){
        props.setCategories([]);
      }
      else
        props.setCategories(res.data);
  }
  function onFailure(res){
  }
  function showCategoriesClick(){
        if (isMobile) {
            setShowCategories(!showCategories);
        }
  }
    return (
      <div id="sidebar" className="menu">
      <ul className="menu-list">
        <li onClick={()=>{setShowCategories(false)}}>
            {props.domain && 
              <Link to={'/domain/'+ props.domain.id} className={"is-pulled-right clickable " + (!props.category ? 'is-active' : '') } >
              Vali kõik</Link>
            }
        </li>
      </ul>
            <p onClick={showCategoriesClick} className={"menu-label is-size-6 m-1 " + (isMobile ? 'clickable':'')}>Teemad: 
                    <i className={"ml-2 fa-solid fa-angles-down " + (!isMobile || showCategories ? 'hide':'')}></i>
                    <i className={"ml-2 fa-solid fa-angles-up " + (!isMobile || !showCategories ? 'hide':'')}></i>
                    </p>
            <p className="is-clearfix"></p>
        <hr />
        <ul className={"categories-list menu-list " + (isMobile && !showCategories ? 'hide':'show')}>
            {props.categories && props.categories.map((object, i) => 

                <li key={object.id}>
                    <Link to={'/domain/' + props.domain.id + '/category/' + object.id} className={(props.categoryId == object.id || props.category && props.category.id == object.id) ? 'is-active' : ''} > 
                                    {object.name}
                    </Link> 

                        {object.children.length>0 && <ul className="menu-list">
                        {object.children.map((ch, i) => 

                            <li key={ch.id}>
                                <Link to={'/domain/' + props.domain.id + '/category/' + ch.id} className={(props.categoryId == ch.id || props.category && props.category.id == ch.id) ? 'is-active' : ''} > 
                                    {ch.name}
                                </Link> 

                                
                            </li>
                        
                        )}
                        </ul>
                      }

                </li>
            
            )}
        </ul>
      </div>
    );
}

