import React from 'react';
import { Link } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import fblogo from '../fblogo.png';
import pinterestlogo from '../pinterestilogo.svg';
import instalogo from '../instalogo.svg';


export default function Footer() {
  return(
   
    <div>
            <hr className="mt-6"/>
            <div className="socialmedia m-6">
                  <a target="_blank" href="https://www.facebook.com/profile.php?id=100089757552411" className="level-item"><img src={fblogo}/></a>
                  <a target="_blank" href="https://www.instagram.com/kokomini_eesti/" className="level-item"><img src={instalogo}/></a>
                  <a target="_blank" href="https://www.pinterest.com/0b01wnzuby8mt9dii9jpxi3rxk8hvi" className="level-item"><img src={pinterestlogo}/></a>
                </div>
      <div id="footer">
            <div className="footercontent">
                <div className="footercolumn">
  {/*                  <h1 className="title is-6 mb-3 mt-5">Ettevõttest</h1>
                    <p><i className="fa-solid fa-briefcase mr-1"></i> Pisiworks OÜ (14790796)</p>
                    <p><i className="fa-solid fa-building mr-1"></i> Välja 12a, Tõrva, Valgamaa 68606</p>
                    <p><i className="fa-regular fa-envelope mr-1"></i> info@kokomini.ee </p>
                    <p><i className="fa-regular fa-message mr-1"></i><Link className="is-underlined" to="/contact/contactus"> Kirjuta meile</Link></p>
*/}

                </div>
                <div className="footercolumn">
                  <h1 className="title is-6 mb-3 mt-5">Tingimused ja juhendid</h1>

                  <p><Link to="/terms/privacy">Privaatsuspoliitika</Link></p>
                  {/*<p><Link to="/terms/sales">Tellimustingimused</Link></p>*/}
                  <p><Link to="/aboutcookies">Küpsistest</Link></p>
                  <p className="mt-2"><Link to="/contact/aboutus">Meist</Link></p>

                </div>
            </div>
          <hr />
          <p className="has-text-centered is-size-7 "> &#169; KOKOmini {new Date().getFullYear()} </p>
      </div>

    </div>
  );
}

