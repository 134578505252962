import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { validateEmail } from './API';



export default function ValidateEmail(props) {

  let { uuid } = useParams();
  const [validated, setValidated] = useState();

  useEffect(()=>{
      validateEmail(uuid).then((res)=>{
        // success
        props.setAccount(res.data);
        setValidated(true);
      }, ()=>{
        // fail
        setValidated(false);
      })
  }, [])

  return(
   
      <div className="container mt-6">
            {validated  && 
            <div className="notification">
                <p>Täname! E-maili aadress kinnitatud!</p>
            </div>
            }
            {!validated && 
            <div className="notification is-warning">
                <p>Tekkis viga e-maili aadressi kinnitamisel!</p>
            </div>
            }
      </div>
  );
}

