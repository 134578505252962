import React, { useEffect, useState } from 'react';
import { getRandomItem } from './API';
import { useNavigate, Link } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';


export default function Spinner() {

  const navigate = useNavigate();
  const [item, setItem] = useState();
  const [loading, setLoading] = useState();

  function spin(){
      setLoading(true);
      getRandomItem().then((res)=>{
          setTimeout(()=>{
              setItem(res.data);
              setLoading(false);
            },1500);
      });
  }

  useEffect(()=>{

  }, []);

  return(

    <div>
          <div id="spinner" className="has-text-centered">
                <h1 className="title is-4">KOKO-ABIRATAS</h1>
                <h6 className="subtitle uppercase is-6">Mida lapsega täna teha?</h6>
                <div className="spinner">
                        {/*ratas tooltipiga keeruta?*/}
                          {!loading && !item &&
                              <Tooltip title="Keeruta" className="clickable ratas" onClick={spin}>
                                  <div className="p-2">
                                    <i className="fas fa-spinner"></i>
                                  </div>
                              </Tooltip>
                          }
                          {loading &&
                              <Tooltip title="Otsin..." className="ratas">
                                  <div className="p-2">
                                    <i className="fas fa-spinner fa-spin"></i>
                                  </div>                              
                              </Tooltip>
                          }
                          {!loading && item &&
                            <Link className="spinnerresult" to={'domain/'+item.domain+'/item/'+item.id}>
                                <img src={item.img}/>
                            </Link>
                          }
                </div>
                <button className="button is-primary is-light is-small" onClick={spin}>{item?'Keeruta veel':'Keeruta'}</button>
          </div>
    </div>
  );
}

