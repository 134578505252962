import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import '../css/navbar.scss';
import Tooltip from '@mui/material/Tooltip';
import Notification from './Notification';

import { logout } from './API';


export default function Navbar(props) {

  const [message, setMessage] = useState("");

  function logoutFn(){
      logout().then(()=>{
        props.setAccount(null);
        props.setLoggedIn(false);
        setMessage("Olete välja logitud.");
      },()=>{
        setMessage("Tekkis viga :(");
      })
  }

  return(

    <div>
      <Notification message={message} setMessage={setMessage}/>
      <nav className="navbar" role="navigation" aria-label="main navigation">
        <div className="container">
        <div className="navbar-brand">
        <Link className="navbar-item" to="/">
            
              <img src="/logo.svg" alt="logo" />
            
          </Link>
          <div>
            <div className="navbar-item">
                <div className="buttons">
                 {/* {props.loggedIn && (!props.account.is_subscribed) && 
                     <Link className="button is-primary is-light is-small" to="/subscribe"><strong>LIITU</strong></Link> 
                  }*/}
                {/*  {props.loggedIn &&  
                  <Link className="accountinfo" to="/dashboard">
                    Tere, {props.account.name}!
                  </Link> }
                */}
                  {props.loggedIn &&
                  <Link to="/dashboard" className="button is-info is-light is-small uppercase">
                     Töölaud
                  </Link>
                  }
                  {props.loggedIn && 
                  <Tooltip title="Konto">
                    <Link className="accountinfo logout" to="/dashboard/account">
                     {/*     <Tooltip title="Logi välja">
                                <i className="fa-solid fa-door-open"></i>
                          </Tooltip>*/}
                      <span><i className="fa-regular fa-user"></i></span>
                    </Link>
                    </Tooltip>
                  }
                  {!props.loggedIn 
                    && 
                    <div>
                       <Link className="button is-primary is-light is-small" to="/register"><strong>LIITU</strong></Link> 
                      
                       <Link className="button is-light is-small" to="/login">Logi sisse</Link>
                     </div>
                  }

                
                </div>
              </div>
            </div>  
          </div>
          </div>
      </nav>
    </div>

  );
}

