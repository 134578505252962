import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, Link, useParams } from 'react-router-dom'

import Tooltip from '@mui/material/Tooltip';

import { getUserPreferences, likeItem, dislikeItem } from './API';
import LoginModal from './LoginModal';
import AddToCalendarModal from './AddToCalendarModal';
import ItemActionButtons from './ItemActionButtons';
import Notification from './Notification';

// SHOWS ITEMS THAT IS GIVEN TO IT, REGARDS PREFERENCES
// props: items, setItems, loggedIn, title, preferences, setPreferences
// disliked, update, collection ...
// isSmall
// vaja saada account või 
export default function ItemsList(props) {

  const [showLoginModal, setShowLoginModal] = useState();
  const [showCalendarModal, setShowCalendarModal] = useState(false);

  const [selectedItem, setSelectedItem] = useState();
  const [showingItems, setShowingItems] = useState([]);
  const [limit, setLimit] = useState(20);
  
  const [notification, setNotification] = useState();


  // mõtlen veidi... niisiiis... 
  // mõte on joonistada divid iga elemendi jaoks koodis
  // st iga kord kui itemslist muutub, teeme otsast peale?
  useEffect(()=>{
      if (props.items && props.items.length > 0) {
          showItems();
      }
  }, [props.items, props.preferences, limit]);

  function showItems(){
      setShowingItems(props.items.slice(0, limit));
  }

  function removeItem(item){
      if (props.removeItem){
          props.removeItem(item);
      } 
      props.setItems(props.items.filter((e)=>{return e.id != item.id}));
  }

  // 
  function likeButtonAction(itemid){
    var prefs = {...props.preferences};
    if (prefs.liked.includes(itemid)){
          prefs.liked = prefs.liked.filter((e)=>{ return e !=itemid});
          props.setPreferences(prefs);
    }
    else {
          prefs.liked = prefs.liked.concat([itemid]);
          props.setPreferences(prefs);
    }
  }
  function hideButtonAction(itemid){
    if (props.preferences){
      var prefs = {...props.preferences};
      if (prefs.disliked.includes(itemid)) {
          prefs.disliked = prefs.disliked.filter((e)=>{ return e !=itemid});
          props.setPreferences(prefs);
      }
      else {
          prefs.disliked = prefs.disliked.concat([itemid]);
          props.setPreferences(prefs);
      }
    }
  }
  function calendarButtonAction(itemid){
  }


  if (props.items && props.items.length==0){
    return (
        <p>Hetkel sisu puudub...</p>
      )
  }

    return (

        <div className="itemslist">
            <Notification message={notification}/>
            <LoginModal showModal={showLoginModal} setShowLoginModal={setShowLoginModal} />
            <AddToCalendarModal 
                    showModal={showCalendarModal}
                    item={selectedItem} 
                    setShowCalendarModal={setShowCalendarModal}
                    setNotification={setNotification}
                    update={props.update}
            />
            <div className="">
                <h1 className="title is-5">{props.title}</h1>
                <p className={"ml-5 has-text-centered " + (showingItems.length==0?'':'is-hidden')}><i className="fas fa-spinner fa-spin fa-xl"></i></p>
                <div className="itemslist wrapping-flex">
                  {showingItems && showingItems.map((item, i) => 

                      <div key={item.id} className={(!props.showDisliked && props.preferences && props.preferences.disliked && props.preferences.disliked.includes(item.id)?'hiddenitem':'')}>
                      <div className="noheight">
                        <div className="levelinfo flex-column">
                                        <p className="">Tase </p>
                                        <p className="title is-3 mb-0">{item.level}</p>
                                        {item.multiple_pages &&
                                          <Tooltip title="Mitu lehte">
                                          <i className="m-1 fa-regular fa-images"></i>
                                          </Tooltip>
                                        }
                        </div>
                      </div>
                        <div key={i} className={"item card clickable " + (props.isSmall ? 'small':'')}> 

                          <Link to={'/domain/' + item.domain + '/item/' + item.id}>
                              <div className="card-image">
                                      <figure className={"image " + (item.is_free?'is-free ':' ') + (props.subscribed ? '':' hidden')}>
                                        <img src={item.img} />
                                      </figure>
                                </div>
                                <div className="card-content flex-row">
                                    <h1 className="subtitle is-6">{item.name}</h1>
                                </div>
                          </Link>
                     {/* {!props.subscribed && !item.is_free &&
                          <Link to={'/subscribe/'}>
                              <div className="card-image">
                                      <div className="accesslimited"><img src="/access-limited.svg"/></div>
                                      <figure className={"image " + (item.is_free?'is-free':'hidden')}>
                                        <img src={item.img} />
                                      </figure>
                                </div>
                                <div className="card-content flex-row">
                                    <h1 className="subtitle is-6">{item.name}</h1>
                                </div>
                          </Link>
                      }*/}
                            {props.preferences && props.preferences.liked && props.preferences.disliked &&
                                <ItemActionButtons 
                                    liked={props.preferences.liked.includes(item.id)}
                                    likeButtonAction={likeButtonAction} 
                                    calendarButtonAction={calendarButtonAction} 
                                    loggedIn={props.loggedIn} 
                                    item={item} 
                                    setSelectedItem={setSelectedItem} 
                                    removeItem={removeItem} 
                                    setShowCalendarModal={setShowCalendarModal} 
                                    setShowLoginModal={setShowLoginModal}
                                    preferences={props.preferences}
                                    disliked={props.preferences.disliked.includes(item.id)}
                                    dislikeAction={hideButtonAction}

                                />
                            }
                            {props.collection && 
                                <ItemActionButtons 
                                    calendarButtonAction={calendarButtonAction} 
                                    setShowCalendarModal={setShowCalendarModal} 
                                    loggedIn={props.loggedIn} 
                                    item={item} 
                                    removeItem={removeItem} 
                                    setShowLoginModal={setShowLoginModal}
                                    collection={props.collection}
                                    setSelectedItem={setSelectedItem} 

                                />
                            }
                            </div>
                        </div>
                    )
                  }
                </div>
                <p className={"mt-3 has-text-centered " + (!showingItems || showingItems.length==0 || (props.items && props.items.length <= limit) ? 'is-hidden':'')}><button className="button is-primary is-light" onClick={()=>{setLimit(limit+20)}}>Lae rohkem</button></p>
          </div>
        </div>
               
    )

}