import logo from './logo.svg';
import './css/kokostyle.scss';
import axios from 'axios';

import React, { useState, useEffect } from 'react';
import { BrowserRouter, Router, Routes, Route, useNavigate, Navigate } from 'react-router-dom';

import Navbar from './components/Navbar';
import Tabs from './components/Tabs';
import Footer from './components/Footer';
import Landing from './components/Landing';
import Login from './components/Login';
import Register from './components/Register';
import ErrorPage from './components/Error';
import Subscribe from './components/Subscribe';
import SubscribeProduct from './components/SubscribeProduct';
import DomainRoute from './components/DomainRoute';
import DashboardRoute from './components/DashboardRoute';
import Construction from './components/Construction';
import LoginModal from './components/LoginModal';
import ScrollUp from './components/ScrollUp';
import CollectionsPage from './components/CollectionsPage';
import MkReturnPage from './components/MkReturnPage';
import ValidateEmail from './components/ValidateEmail';
import Terms from './components/Terms';
import Contact from './components/Contact';
import Proposal from './components/Proposal';
import ScrollOnRoute from './components/ScrollOnRoute';
import CookieConsent from './components/CookieConsent';
import AboutCookies from './components/AboutCookies';

import { authenticate, logout, getDomains } from './components/API';
 

export default function App() {

  const [loggedIn, setLoggedIn] = useState(false);
  const [account, setAccount] = useState({});
  const [domains, setDomains] = useState([]);
  const [domain, setDomain] = useState({});
  const [token, setToken] = useState({});
  const [subscribed, setSubscribed] = useState(false);

  useEffect(()=>{
        setSubscribed(account.is_subscribed);
  },[account])

// SIIN EI SAA USELOCATIONIT KASUTADA, ära 5. korda proovi PALUN!
    useEffect(() => {
        authenticate().then(function(res){
          setLoggedIn(true);
          setAccount(res.data);
        }, setLoggedIn(false));

      getDomains().then((res)=>{
          setDomains(res.data);
      });
    }, []);

  return (
    <div>
    <ScrollUp />
    <React.StrictMode>
        <BrowserRouter>
        <LoginModal />
        <Proposal />
        <Navbar loggedIn={loggedIn} subscribed={subscribed} account={account} setLoggedIn={setLoggedIn} setAccount={setAccount}/>
            <Tabs domains={domains} domain={domain} setDomain={setDomain} />
            {/*<Construction title="Leht on valmimisel" message="Võid julgelt ringi vaadata, aga hetkel veel enamik asju ei tööta :)" />*/}
            <Routes>
                <Route index path="/" element={<Landing/>} errorPage={<ErrorPage />} />
                <Route index path="/home" element={<Landing/>} errorPage={<ErrorPage />} />
                <Route path="/domain/*" element={<DomainRoute subscribed={subscribed} domains={domains} domain={domain} setDomain={setDomain} account={account} loggedIn={loggedIn}/>} errorPage={<ErrorPage />} />
                <Route path="/collection/:id" element={<CollectionsPage subscribed={subscribed} domains={domains} domain={domain} setDomain={setDomain} account={account} loggedIn={loggedIn}/>} errorPage={<ErrorPage />} />

                <Route path="/contact/:route" element={<Contact loggedIn={loggedIn} account={account} setLoggedIn={setLoggedIn} setAccount={setAccount}/>} errorPage={<ErrorPage />} />
                <Route path="/login" element={<Login loggedIn={loggedIn} account={account} setLoggedIn={setLoggedIn} setAccount={setAccount}/>} errorPage={<ErrorPage />} />
                <Route path="/register" element={<Register loggedIn={loggedIn} setLoggedIn={setLoggedIn} setAccount={setAccount}/>} errorPage={<ErrorPage />} />
                <Route path="/dashboard/*" element={<DashboardRoute subscribed={subscribed} account={account} setAccount={setAccount} loggedIn={loggedIn} setLoggedIn={setLoggedIn} />} errorPage={<ErrorPage />} />
                <Route path="/payment/:status" element={<MkReturnPage loggedIn={loggedIn} setLoggedIn={setLoggedIn} account={account} setAccount={setAccount}/>} errorPage={<ErrorPage />}/>
                <Route path="/subscribe/:productname" element={<SubscribeProduct loggedIn={loggedIn} account={account} setAccount={setAccount}/>} errorPage={<ErrorPage />}/>
                <Route path="/subscribe" element={<Subscribe loggedIn={loggedIn} account={account} setAccount={setAccount}/>} errorPage={<ErrorPage />}/>
                <Route path="/emailvalidation/:uuid" element={<ValidateEmail loggedIn={loggedIn} account={account} setAccount={setAccount}/>} errorPage={<ErrorPage />}/>
                
                <Route path="/aboutcookies" element={<AboutCookies />}/>
                <Route path="/terms/:topic" element={<Terms />} errorPage={<ErrorPage />}/>

                <Route path="/error" element={<ErrorPage />}/>
            </Routes>
            <CookieConsent />
            <ScrollOnRoute />
            <Footer />
        </BrowserRouter>
  </React.StrictMode>
</div>

  );
}


