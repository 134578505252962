import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, Link, useParams } from 'react-router-dom'
import Tooltip from '@mui/material/Tooltip';


import { likeItem, dislikeItem, removeItemFromCollection } from './API';

// 
export default function ItemActionButtons(props) {

   function action(act){
    if (props.loggedIn){
        if (props.setSelectedItem)
            props.setSelectedItem(props.item);
        switch(act){
        case 'like':
            likeItem(props.item);
            props.likeButtonAction(props.item.id);
            break;
        case 'dislike':
            dislikeItem(props.item).then(()=>{
                if(props.setHiddenItemsIds && props.hiddenItemsIds)
                props.setHiddenItemsIds(props.hiddenItemsIds.concat([props.item.id]));
                if (props.removeItem)
                  props.removeItem(props.item)
                if (props.dislikeAction)
                    props.dislikeAction(props.item.id);

            });
            break;
        case 'addToCalendarOrCollection':
            if (props.setShowCalendarModal){
              props.setShowCalendarModal(true);
            }
            break;
        case 'undislike':
            dislikeItem(props.item);
            if(props.removeItem)
                props.removeItem(props.item);
            if(props.dislikeAction)
                props.dislikeAction(props.item.id);
            break;
        case 'removeFromCollection':
            if (window.confirm("Soovid materjali kogumikust eemaldada?")){
                removeItemFromCollection(props.item, props.collection).then((res)=>{
                      props.removeItem(props.item);
                });
            }
            break;
        }
    }
    else {
        if(props.setShowLoginModal)
          props.setShowLoginModal(true);
    }
  }

  // 
  if (props.collection)
    return(
          <footer className="card-footer itemactionbuttons">

                    <p className="card-footer-item" onClick={()=>{action('addToCalendarOrCollection')}}>
                                          <Tooltip title="Lisa kalendrisse või kogumikku">
                                            <i className="fa-regular fa-calendar-plus"></i>
                                          </Tooltip>
                    </p>
                    <p className="card-footer-item clickable" onClick={()=>{action('removeFromCollection')}}>
                                         <Tooltip title="Eemalda kogumikust">
                                                <i className="fa-regular fa-trash-can"></i>
                                        </Tooltip>
                    </p>
        </footer>
        )
    // item page
    else if (props.itempage) 
        return (
            <div>
            </div>
            )
    else

    return (

        <footer className="card-footer itemactionbuttons">
                                <p className="card-footer-item" onClick={()=>{action('like')}}>
                                            {!props.liked && 
                                                <Tooltip title="Lisa lemmikutesse">
                                                    <i className="fa-regular fa-heart"></i>
                                                </Tooltip>
                                            }
                                            {props.liked && 
                                                <Tooltip title="Eemalda lemmikutest">
                                                    <i className="fa-solid fa-heart"></i>
                                                </Tooltip>
                                            }

                                </p>
                                 <p className="card-footer-item" onClick={()=>{action('addToCalendarOrCollection')}}>
                                          <Tooltip title="Lisa kalendrisse või kogumikku">
                                            <i className="fa-regular fa-calendar-plus"></i>
                                          </Tooltip>
                                </p>

                            {!props.disliked && 
                                <p className="card-footer-item" onClick={()=>{action('dislike')}}>
                                          <Tooltip title="Ära seda enam näita">
                                          <i className="fa-regular fa-eye-slash"></i>
                                          </Tooltip>
                                </p>
                            }
                            {props.disliked &&
                                 <p className="card-footer-item clickable disliked" onClick={()=>{action('undislike')}}>
                                    <Tooltip title="Ära peida enam">
                                          <i className="fa-regular fa-eye-slash"></i>
                                    </Tooltip>
                                </p>
                            }
                            {props.collection &&
                                <p className="card-footer-item clickable" onClick={()=>{action('removeFromCollection')}}>
                                    <Tooltip title="Eemalda kogumikust">
                                          <i className="fa-regular fa-trash-can"></i>
                                    </Tooltip>
                                </p>
                            }
                </footer>
               
    )

}