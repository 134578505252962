import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, Link, useParams } from 'react-router-dom'
import Tooltip from '@mui/material/Tooltip';

import { getCalendar, getCalendar6Days, getCalendarDay, toggleDoneCalendarItem, addItemToCalendar, deleteCalendarItem } from './API';

export default function Calendar(props) {

  const [calendar, setCalendar] = useState();
  const [current, setCurrent] = useState();
  const [monday, setMonday] = useState();
  const [currentDate, setCurrentDate] = useState(new Date());
  // in time (ms)
  const [today, setToday] = useState();
  const [newNote, setNewNote] = useState("");
  const [showAdd, setShowAdd] = useState();

  const [loading, setLoading] = useState();

  const week = [
          {name:'Esmaspäev', short:'E'},
          {name:'Teisipäev', short:'T'},
          {name:'Kolmapäev', short:'K'},
          {name:'Neljapäev', short:'N'},
          {name:'Reede', short:'R'},
          {name:'Laupäev', short:'L'},
          {name:'Pühapäev', short:'P'},
      ];

  useEffect(()=>{
    setToday(getDate(new Date()));
    getCal();
  }, [props.update])

  function getCal(baseday){
    console.log(baseday);
    if (!baseday)
      baseday=currentDate;
    baseday.setHours(0,0,0,0);
    var week_day = baseday.getDay() == 0 ? 6 : baseday.getDay()-1;
    // showWeekday(week_day);
    var week_start = new Date(baseday);
    week_start.setDate(baseday.getDate()-week_day);
    setMonday(week_start);
    setCurrentDate(baseday);

      // get calendar items and add to list items and week dates
    getCalendar(getDateString(week_start)).then((res)=>{
        console.log(res.data);
        for (let i=0; i<7;i++){
            var idate = new Date(week_start);
            idate.setDate(week_start.getDate()+i);
            week[i].items = res.data.filter((e)=>{return getDate(idate) == getDate(new Date(e.date))});
            week[i].date = idate;
        }
          setCalendar(week);
          setLoading(false);
    });
  }

  function showWeekday(day, i) {
      setCurrentDate(day.date);
      if (current == day) {
        setCurrent(null);
      }
      else  {
        setCurrent(i);
      }
  }
  function prevWeek(){
      setLoading(true);
      setTimeout(()=>{
        var newdate = new Date(monday);
        newdate.setDate(monday.getDate()-7);
        getCal(newdate);
        setCurrent(null);
      }, 300);
  }
  function nextWeek(){
      setLoading(true);
        setTimeout(()=>{
          var newdate = new Date(monday);
          newdate.setDate(monday.getDate()+7);
          getCal(newdate);
          setCurrent(null);
      }, 300);
  }


  function getDate(date){
    return (new Date(date.getFullYear(), date.getMonth(), date.getDate())).getTime();
  }
    function getDateString(date){
      var d = (new Date(date.getFullYear(), date.getMonth(), date.getDate()))
      var month = d.getMonth() < 10 ? "0" + (d.getMonth()+1) : d.getMonth()+1;
      var day = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
    return d.getFullYear() + month  + day;
  }

    function markDone(event, item){
          event.stopPropagation();
          item.done = !item.done;
            if(!item.item){
              item.item = {};
            }
            toggleDoneCalendarItem(item).then((res)=>{
              getCal(currentDate);
            });
  }

  function onNoteChange(event){
      setNewNote(event.target.value);
      setShowAdd(event.target.value.length > 0);
  }
  function saveNote(){
        var date_str = currentDate.getFullYear().toString() + '-' + (currentDate.getMonth()+1).toString() + '-' + currentDate.getDate().toString();
        addItemToCalendar({item:{},content:newNote, itemType:'note', date:date_str}).then((res)=>{
        getCal(currentDate);
        setNewNote("");
        setShowAdd(false);

    });
  }
  function onKeyDown(event){
    if (event.key === 'Enter') {
      saveNote();
    }
  }
    function deleteNote(e, item){
      deleteCalendarItem(item).then(()=>{
        getCal();
      });
      e.stopPropagation();
  }

    return (

        <div id="calendar" className="mb-3">
          <h1 className="title is-5">Kalender</h1>
              <p className="has-text-centered mb-2"><button onClick={()=>{setCurrent(null);getCal(new Date())}} className="button is-small">Täna</button></p>
          <div className={"week " + (loading?'loading':'')}>
              <div className="arrow clickable" onClick={prevWeek}><i className="fa-solid fa-circle-chevron-left"></i></div>
              {calendar && calendar.map((d, i)=>
                  <div key={d.short} className={"day boxed clickable " + (current == i ? 'current' : '')} onClick={()=>{showWeekday(d, i)}}>                    
                      <p className="has-text-centered">{d.date && getDate(d.date) == today && <span className="tag is-warning is-pulled-right">Täna</span>}</p>
                      <p className="has-text-centered">{i == current ? d.name : d.short}</p>
                      <p className="has-text-centered date">{d.date.getDate()}.{d.date.getMonth()<10?'0':''}{d.date.getMonth()+1}</p>
                      <hr />
                      {d.items && d.items.map((item)=>
                          <div  key={item.id}>
                            {!item.item && 
                                    <div className={item.done ? 'done':''}>
                                      {i==current &&
                                        <Tooltip title={item.done?"Tegemata":"Märgi tehtuks"}>
                                            <i className="mr-1 fa-solid fa-check clickable" onClick={(e)=>{markDone(e, item)}}></i>
                                        </Tooltip>
                                      }
                                        {item.name} {item.content}
                                      {i==current &&
                                        <Tooltip title="Kustuta">
                                              <i className="ml-3 fa-solid fa-xmark clickable" onClick={(e)=>{deleteNote(e, item)}}></i>
                                          </Tooltip>
                                      }
                                    </div>
                                }
                                {item.item &&
                                  <div className={'item ' + (item.done ? 'done':'')}>
                                      <div className={"boxed " + (item.item.domain==1?'is-purple ':'') + (item.item.domain==2?'is-orange ':'') + (item.item.domain==3?'is-blue ':'')}>
                                          {i==current &&
                                            <Tooltip title={item.done?"Tegemata":"Märgi tehtuks"}>
                                                <i className="ml-1 fa-solid fa-check clickable" onClick={(e)=>{markDone(e, item)}}></i>
                                            </Tooltip>
                                          }
                                          <Link to={'/domain/'+item.item.domain + '/item/' + item.item.id}>{item.item.name}</Link>
                                          {i==current &&
                                            <Tooltip title={"Kustuta"}>
                                                <i className="ml-3 fa-solid fa-xmark clickable" onClick={(e)=>{deleteNote(e, item)}}></i>
                                            </Tooltip>
                                          }
                                      </div>
                                  </div>
                                }
                          </div>
                        )}
                          
                        {current == i && 
                          <div>
                              <input onClick={(e)=>{e.stopPropagation();}} placeholder="...lisa" type="text" className="input noteinput mt-2 mb-2" value={newNote} onChange={onNoteChange} onKeyDown={onKeyDown}/>
                              {showAdd && 
                                    <button className="button is-primary is-light is-small" onClick={saveNote}>Sisesta</button>
                              }
                            </div>
                        }
                  </div>
                )}
              <div className="arrow clickable" onClick={nextWeek}><i className="fa-solid fa-circle-chevron-right"></i></div>
          </div>
          
          <p className="has-text-centered mt-2"><button className="button is-primary is-small is-light" onClick={()=>{props.showCalendar(false)}}>Päeva vaade</button></p>

        </div>
               
    )

}