import React, {Container} from 'react';
import ReactDOM from 'react-dom/client';
import './css/index.scss';
import reportWebVitals from './reportWebVitals';

import App from './App';



// JAVASCRIPT
        Date.prototype.showDate = function() {
          var mm = this.getMonth() + 1; // getMonth() is zero-based
          var dd = this.getDate();
          return [
                  (dd>9 ? '' : '0') + dd,
                  (mm>9 ? '' : '0') + mm,
                  this.getFullYear()
                 ].join('.');
        };

        Date.prototype.addDays = function(days) {
            let date = new Date(this.valueOf());
            date.setDate(date.getDate() + days);
            return date;
        }


          Date.prototype.rtf = function() {
            return (Intl &&
              Intl.RelativeTimeFormat &&
              new Intl.RelativeTimeFormat('et', {
                 style: 'short',
                 numeric: "auto",
              })) || false;
          };


// IN THIS FILE: adding NAVBAR, ROUTER, FOOTER
const root = ReactDOM.createRoot(document.getElementById('root'));



root.render(
        <App />
);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
