import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Cookies from 'js-cookie';

import { authenticate } from './API';


export default function MkReturnPage(props) {

  let { status } = useParams();

  useEffect(()=>{
    authenticate().then((res)=>{
        console.log(res.data);
        props.setAccount(res.data);
        props.setLoggedIn(true);
    })
  },[])

      return(
      
      <div className="container mt-6">

        { status == 'success' && 
            <div className="notification">
                 <p> Makse õnnestus. Head kasutamist! </p>
            </div>
        }
        {status == 'cancelled' && 
            <div className="notification">
                 <p> Tehing katkestatud. </p>
            </div>
        }
        {status == 'error' && 
            <div className="notification is-info">
                 <p> Tekkis viga. Proovige uuesti või võtke ühendust: info@kokomini.ee </p>
            </div>
        }
      </div>
  );

}

