import React, { useEffect, useState } from 'react';
import { LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import 'moment/locale/et';
import Notification from './Notification';
import { addItemToCalendar, getUserCollections, addNewCollection, addToCollection } from './API';


export default function AddToCalendarModal(props) {




  const [date, setDate] = useState(new Date());
  const [notification, setNotification] = useState();
  const [selected, setSelected] = useState();
  const [selectedCollection, setSelectedCollection] = useState();
  const [collections, setCollections] = useState();
  const [collectionName, setCollectionName] = useState();
  const [showAdd, setShowAdd] = useState(false);


  const [transition, setTransition] = useState(false);
  // item added, close modal soon
  const [added, setAdded] = useState(false);



  useEffect(()=>{
  }, [date])


  function init(){
    setDate(new Date());
    setSelected(null);
    setSelectedCollection(null);
    setCollectionName(null);
    setShowAdd(null);
  }

  useEffect(()=>{
      if(props.selected)
        setSelected(props.selected);
  }, [props.selected])


  useEffect(() => {
        if (selected && selected=='collection'){
            getUserCollections().then((res)=>{
              setCollections(res.data.filter((e)=>{return e.collectionType == 'CUSTOM' }));
            });
        }
  }, [selected]);

  function addToCalendar(item){
    var date_str = date.getFullYear().toString() + '-' + (date.getMonth()+1).toString() + '-' + date.getDate().toString();
    var calitem = {item:{id:item.id}, date:date_str, itemType:'contentitem'};
  	addItemToCalendar(calitem).then((res)=>{
      props.setShowCalendarModal(false);
      setNotification("Lisatud kalendrisse");
      if (props.update){
        props.update();
      }

    });
  }
  function addNew(){
    addNewCollection(collectionName).then((res)=>{
        setCollections(collections.concat([res.data]));
        setShowAdd(false);
        if(props.update)
            props.update();
        setNotification("Uus kogumik loodud");
    }, (res)=>{
        setNotification("Kogumiku loomisel tekkis viga");
    });
  }
  function cancelAdd(){
        setCollectionName('');
        setShowAdd(false);
  }
  function addItemToCollection(){
    if (!props.item || !selectedCollection){
        alert("Oops, midagi jäi valimata...");
        return;
    }
        addToCollection(props.item, selectedCollection).then((res)=>{
            setNotification("Lisatud kogumikku");
            props.setShowCalendarModal(false);
            // vb järgnev üleliigne... 
            var cols = [...collections];
            cols.forEach((col)=>{
              if (col.id == selectedCollection.id) {
                col.items.push(props.item);

              }
            });
            setCollections(cols);
            setAdded(true);
            if(props.update)
                props.update();
        }, (res)=>{
            setNotification("Lisamine ebaõnnestus");
        });
  }
  // function showNotification(msg){
  //     setNotification(msg);
  //     setTransition(true);
  //     setTimeout(()=>{
  //       setTransition(false);
  //       setTimeout(()=>{
  //         setNotification(null);
  //       }, 2000)
        
  //     }, 3000);
  // }
  function close(){
      props.setShowCalendarModal(false);
      init();
  }

    if (!props.item){
        return (<div></div>)
    }
    else
    return (
    <div>
        <Notification message={notification} setMessage={setNotification}/>
   	  <div className={"modal modal-hide " + (props.showModal ? "is-active" : "hidden")}>

          <div className="modal-background"></div>
          <div id="calendarcollectionmodal" className="modal-content">
            	
            {/* CALENDAR OR COLLECTIONS */}
              <div className={(selected ? 'hide ':'') + 'box transition'} style={{maxHeight: '300px'}}>
                  <div className="level mb-2">
                      <div className="level-item has-text-centered hoverable box mb-0 m-3" onClick={()=>{setSelected('calendar')}}>
                        <div>
                          <p className="title mb-3">
                              <i className="fa-solid fa-calendar-days"></i>
                          </p>
                          <p className="heading">Kalendrisse</p>
                        </div>
                      </div>

                      <div className="level-item has-text-centered hoverable box m-3" onClick={()=>{setSelected('collection')}}>
                        <div>
                          <p className="title mb-3">
                              <i className="fa-regular fa-bookmark"></i>
                          </p>
                          <p className="heading">Kogumikku</p>
                        </div>
                      </div>
                  </div>  
                  <p className="has-text-centered p-0"><button className="minimal" onClick={close}>Tagasi</button></p>
              </div>

              {/* ADD TO CALENDAR */}
              <div className={(selected && selected=='calendar' ? ' ':'hide ') + 'box has-text-centered transition'} style={{maxHeight: '2000px'}}>
                <h1 className="title is-4">Lisa kalendrisse</h1>
	          	<div className="columns">
	          		<div className="column is-one-third">
	          			<div className="block"><img className="modal-image" src={props.item.img}/></div>
	          		</div>
	          		<div className="column is-two-thirds">

                      <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="et" >
                        <StaticDatePicker showDaysOutsideCurrentMonth={true}
                          slots={{actionBar:{actions:[]}}}
                         showToolbar={false}
                                       closeOnSelect={false}         renderInput={(params) => <TextField {...params} />}
                                        id="calendar" open={true} closeOnSelect={false} value={date} onChange={(d) => {setDate(d._d)}} />
                      </LocalizationProvider>
	          		</div>
	          	</div>
                <p>
                    <button className="button" aria-label="close" onClick={close}>Sulge</button>
                    <button className="button is-primary ml-2" aria-label="close" onClick={()=>{addToCalendar(props.item)}}>Lisa</button>
                </p>
            </div>

            {/* ADD TO COLLECTION */}
             <div id="collections" className={(selected && selected=='collection' ? ' ':'hide ') + 'box has-text-centered transition'} style={{maxHeight: '2000px'}}>
                 <p className="p-1"> 
                                        <i className="fa-solid fa-xmark is-pulled-right" onClick={close}></i>
                                        <span className="is-clearfix"></span>
                </p>
                <h1 className="title is-4">Lisa kogumikku</h1>
                <div className="columns">
                    <div className="column is-half">
                        <ul className="menu-list">
                            {collections && collections.map((c, i)=>
                              <li key={i} className={'menu-item ' + (c.items && c.items.find(e => e.id == props.item.id) ?' is-orange ':'')}>
                              <a className={(selectedCollection && selectedCollection.id == c.id ? 'is-active':'')} onClick={()=>{setSelectedCollection(c)}}>
                                    {c.name} 
                                    {/*{c.items && ("(" +c.items.length + ")")}*/}
                              </a>
                              </li>
                            )}
                              <li className="menu-item ml-3"><a onClick={()=>{setShowAdd(true)}}>
                                  <i className="fa-solid fa-plus"></i> Loo uus
                              </a></li>
                        </ul>

                        <div>
                         <Tooltip title="Värvilisena on märgitud kogumikud, kuhu on element juba lisatud.">
                                <i className="fa-regular fa-circle-question is-orange"></i>
                        </Tooltip>
                        </div>
                          {/* new collection */}
                              {showAdd && 
                                  <div className={"addnew has-text-centered p-2 transition " + (showAdd ? '':'hide')} style={{maxHeight:'300px'}}>
                                    <p className="p-1"> 
                                        <i className="fa-solid fa-xmark is-pulled-right" onClick={cancelAdd}></i>
                                        <span className="is-clearfix"></span>
                                    </p>
                                    
                                    <p><input onChange={(v)=>{setCollectionName(v.target.value)}} className="input mb-1" name="collectionname" placeholder="Kogumiku nimi"/></p>
                                    <div className="has-text-centered">
                                        <button className="button is-small" onClick={addNew}>Salvesta</button>
                                    </div>
                  
                                  </div>
                              }
                    </div>
                    <div className="column is-half p-20">
                        <div className="block"><img className="modal-image" src={props.item.img}/></div>
                    </div>
                </div>

                    <div className="has-text-centered">
                          <p className={"tag is-success transition-fade " + (transition ? '':'transition-fade-hidden')}>{notification}</p>
                              <p className="mt-3">
                                  <button className="button" aria-label="close" onClick={close}>Sulge</button>
                                  <button className="button is-primary ml-2 " disabled={!selectedCollection} aria-label="close" onClick={()=>{addItemToCollection()}}>Lisa</button>
                              </p>
                    </div>

              
            </div>
          </div>
        </div>
      </div>

    );
}