import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, useParams, Link} from 'react-router-dom'
import { getContentItem, getItemFile, getUserPreferences, dislikeItem, likeItem } from './API';
import LoginModal from './LoginModal';
import AddToCalendarModal from './AddToCalendarModal';
import Notification from './Notification';
import ItemActionButtons from './ItemActionButtons';
import Tooltip from '@mui/material/Tooltip';


// props: subscribed
export default function DomainCategoryItemPage(props) {


  const navigate = useNavigate();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);

  const [showLoginModal, setShowLoginModal] = useState();
  const [showCalendarModal, setShowCalendarModal] = useState();
  const [selectedItem, setSelectedItem] = useState();
  const [notification, setNotification] = useState();
  const [preferences, setPreferences] = useState();
  const [liked, setLiked] = useState();
  const [disliked, setDisliked] = useState();
  const [calendarOrCollection, setCalendarOrCollection] = useState();

// kui contentitem olemas, küsi preferences....
  useEffect(()=>{
    if (props.contentItem) {
        getUserPreferences().then((res)=>{
            setPreferences(res.data);
            if (res.data.liked && res.data.liked.includes(props.contentItem.id)){
                setLiked(true);
            }
            if (res.data.disliked && res.data.disliked.includes(props.contentItem.id)){
                setDisliked(true);
            }
            setLoading(false);
        });
    }
  }, [props.contentItem]);

  function gotoPage(url){
        window.location.href = url;
  }

  function action(act){
    if (props.loggedIn && props.contentItem){
        switch(act){
        case 'like':
            likeItem(props.contentItem);
            setLiked(!liked);
            break;
        case 'dislike':
            dislikeItem(props.contentItem);
            setDisliked(!disliked);
            break;
        case 'addToPlanner':
            setCalendarOrCollection('calendar');
            setShowCalendarModal(true);
            break;
        case 'addToCollection':
            setCalendarOrCollection('collection');
            setShowCalendarModal(true);
        }
    }
    else {
        setShowLoginModal(true);
    }
  }

  function download(){
    getItemFile(props.contentItem.id).then((res)=>{
            console.log(res);
            var file = window.URL.createObjectURL(res.data);
            window.location.assign(file)

            // gotoPage(res.data.file);

    }, (res)=>{
            // setNotification("Ligipääs puudub.");
    });
  }

  function draw(){
    gotoPage("https://kokodraw.alatiolemas.ee?"+props.contentItem.id)
        }
  //   getItemFile(props.contentItem.id).then((res)=>{
  //       console.log(res.data);
  //       let f = res.data.file.split("/").pop();
  //       gotoPage("http://www.localhost:8000/draw?"+f);
  //   }, (res)=>{
  //           setNotification("Ligipääs puudub.");
  //   });     
  // }

      if (props.loading || !props.contentItem){
            return (
                <div className="has-text-centered p-6">
                        <i className="fas fa-spinner fa-spin fa-5x"></i>
                </div>
            )
      }
      else if (!props.contentItem)
        return (
            <p>Sisu puudub.</p>
            );
    else

    return (
            <div id="itempage">
                <hr />
                <div className="columns">
                    <LoginModal showModal={showLoginModal} setShowLoginModal={setShowLoginModal} />
                    <AddToCalendarModal item={props.contentItem} selected={calendarOrCollection}  showModal={showCalendarModal} setShowCalendarModal={setShowCalendarModal}/>
                    <Notification message={notification} setMessage={setNotification}/>
                    <div className="column has-text-centered space-between flexbox flex-column">
                          <h1 className="title mb-0"> {props.contentItem.name}</h1>
                          {props.contentItem.multiple_pages &&
                            <Tooltip title="Mitu lehte">
                                <p><i className="m-1 fa-regular fa-images"></i></p>
                            </Tooltip>
                            }
                          <p className="mt-3">{props.contentItem.desc}</p>

                          <div className="clickable itemactions mt-6">
                                <p>
                                    <button className="button is-link" onClick={download}>Lae alla</button>
                                </p>
                                {!liked && 
                                        <Tooltip title="Lisa lemmikutesse">
                                                <p className="" onClick={()=>{action('like')}}>
                                                    <i className="fa-regular fa-heart ml-0"></i>
                                                </p>
                                        </Tooltip>
                                }                                
                                {liked && 
                                        <Tooltip title="Eemalda lemmikutesse">
                                                <p className="" onClick={()=>{action('like')}}>
                                                    <i className="fa-solid fa-heart ml-0"></i>
                                                </p>
                                        </Tooltip>
                                }
                                   <p onClick={()=>{action('addToPlanner')}}>
                                    <span> 
                                    Lisa kalendrisse
                                    {/*<i className="fa-solid fa-calendar-days"></i>*/}
                                    </span>
                                  </p>
                                    <p onClick={()=>{action('addToCollection')}}>
                                    <span> 
                                    Lisa kogumikku 
                                    {/*<i className="fa-regular fa-bookmark"></i>*/}
                                    </span>
                                  </p>
                                {!disliked && 
                                    <p onClick={()=>{action('dislike')}}>
                                            <Tooltip title="Ei kuvata enam lehel.">
                                                <span> Peida 
                                                <i className="fa-regular fa-eye-slash"></i>
                                                </span>
                                            </Tooltip>
                                    </p>
                                }
                                {disliked && 
                                    <p onClick={()=>{action('dislike')}}>
                                            <Tooltip title="">
                                                <span> Ära peida enam </span>
                                            </Tooltip>
                                    </p>
                                }
                                    <p><button className="button is-primary" onClick={draw}>
                                            {props.contentItem.domain == 2 &&
                                                "Lahenda veebis"
                                            }
                                                {props.contentItem.domain != 2 &&
                                                            "Joonistama"
                                                }
                                    </button></p>
                        </div>
                    </div>
                     <div className="column">
                          <img className="image clickable" src={props.contentItem.img} onClick={download}/>

                    </div>
                </div>
            </div>
    );
}

