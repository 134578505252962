import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import Spinner from './Spinner';
import { getDomains } from './API';


export default function Landing(props) {

  const [domains, setDomains] = useState([]);
  const [domain, setDomain] = useState([]);
  const path = useLocation().pathname;

  useEffect(()=>{
       getDomains().then((res)=>{
          setDomains(res.data);
       });
  }, [])

  return(
   
      <div id="landing">
        <div className="container pt-5">
        <div className="columns">
          
          <div className="column is-two-thirds has-text-centered">
            <img className="spinner-side-img" src="spinner-side-img.jpeg" />
          </div>
          <div className="column has-text-centered">
            <Spinner />
          </div>
        </div>
        </div>

{/*        <section className="wrapping-flex has-text-centered container mg-large">
              <div>NÄDALA PLANEERIJA</div>
              <div>RAHALINE KOKKUHOID</div>
              <div>PALJU ERINEVAID TEGEVUSI</div>
              <div>VALITUD LEHED PRINDI ÜHE KORRAGA</div>
        </section>*/}

        <section className="hero is-medium">
            <div className="hero-body container">
              <div className="columns">
              <div className="column">
                <h1 className="title">Töölehed</h1>
                <h2 className="subtitle">Numbrid, tähed, ristsõnad, labürindid ja palju muud.</h2>
                <p>Kiire moodus laps laua taha pusima saamiseks. Ja ohhoo - õpib seal igasugu uusi asju kah!</p>
                <p className="has-text-centered">
                <Link to="/domain/2" className="button is-primary is-worksheets">
                    Vaata töölehti
                </Link>
                </p>
              </div>
              <div className="column has-text-centered">
                <img src="worksheets.jpg" height="300" />
              </div>
            </div>
            </div>
        </section>


        <section className="hero is-light">
            <div className="hero-body container">
              <div className="columns mobile-reversed">
              <div className="column has-text-centered">
                <img src="library.png" height="300" />
              </div>
              <div className="column">
                <h1 className="title">Raamatukogu </h1>
                {/*<h2 className="subtitle">Jutud ettelugemiseks ning <strong>suures kirjas</strong> lihtsad lood lapsele lugemise õppimiseks.</h2>*/}
                {/*<p>Lugege ekraanilt või printige välja ja köitke.</p>*/}
                {/*<p> Raamatud on toredad, aga vahel on kiusatus raamatu enda säästmiseks see lapse uudishimulike (loe hävituslike) käte vahelt ära võtta. Ent lapse lugemis- ja uurimishuvi ei tohiks takistada. Seetõttu ongi hea oma lemmiklood välja <strong> printida </strong>, soovi korral <strong>köita</strong> kokku, ning siis ei pea muretsema, kui lastele omase uudishimu tõttu mõned lehed pooleks rebitakse või illustratsioone lisatakse. Saab lihtsalt uued printida.*/}
                {/*</p>*/}
                <p>Raamatukogust leiab midagi nii ettelugemiseks kui lugema õppimiseks, nii lugusid, luuletusi kui huvitavat ja harivat.</p>
                <p className="has-text-centered">
                  <Link className="button is-primary is-stories" to="/domain/1">
                    Raamatukokku
                  </Link>
                </p>
              </div>
            </div>
            </div>
        </section>

        <section className="hero is-medium">
            <div className="hero-body container">
              <div className="columns">
              <div className="column">
                <h1 className="title">Mängud</h1>
                <h2 className="subtitle">Mängud tuppa ja õue.</h2>
                <p>Vahel ei tule lihtsalt ühki ideed pähe, mida lapsega mängida. KOKOminist saab kiirelt inspiratsiooni või mõni hea vana mäng meelde tuletatud.</p>
                <p className="has-text-centered">
                  <Link className="button is-primary is-games" to="/domain/3">
                    Vaata mänge
                  </Link>
                </p>
              </div>
              <div className="column has-text-centered">
                <img src="games.png" height="300" />
              </div>
            </div>
            </div>
        </section>

        <section className="section is-orange">
          <div className="container">
              <div className="columns">
                  <div className="column">
                      <h1 className="title">Kogumikud</h1>
                      <p> Kogumikud on hea moodus materjalide koondamiseks <strong>teema</strong>, <strong>lapse</strong> või <strong>taseme</strong> järgi. Kogumikud võimaldavad ka palju materjale ühe nupuvajutusega välja printida. </p>
                  </div>
                  <div className="column">
                      <h1 className="title">Kalender</h1>
                      <p> Usinad etteplaneerijad saavad nädala <strong>planeeritud tegevused</strong> kalendrisse märkida, siis leiab need kiirelt oma töölaualt üles. </p>
                  </div>
{/*                  <div className="column">
                      <h1 className="title">Sinu eelistused</h1>
                      <p> Märgi tehtud või "ei soovi" - neid materjale sulle rohkem ei kuvata. </p>
                      <p> "Soovin teha" märgistusega materjale kuvatakse töölaual, kus neid kiirelt leida saab. </p>
                      <p> Märkides materjale "meeldivaks", annab meile tagasisidet, missugust sisu rohkem lisada. </p>
                  </div>*/}
                  <div className="column">
                      <h1 className="title">Tasemed</h1>
                      <p> Tasemete järgi on hea sisu <strong>filtreerida</strong>, et leida kiirelt oma lapsele sobiv.</p>
                  </div>
                  
                </div>
          </div>
        </section>


      </div>

  );
}

