import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from "react-router-dom";
import { logout, getUserSubscriptions, resendEmailActivation } from './API';
import Tooltip from '@mui/material/Tooltip';


// props: subscribed, account
export default function Account(props) {

  const [subscriptions, setSubscriptions] = useState();
  const [show, setShow] = useState();
  const [cancelledDate, setCancelledDate] = useState();
  const [isNewAccount, setIsNewAccount] = useState();
  const [sentAgain, setSentAgain] = useState();

  useEffect(()=>{
      if(props.account && props.account.is_cancelled) {
          setCancelledDate(new Date(props.account.is_cancelled));
      }

      // set isNewAccount
       let trial_until = (new Date(props.account.created_at)).addDays(3);
      console.log(trial_until);
      if (trial_until < new Date())
          setIsNewAccount(false);
      else
          setIsNewAccount(true);
  },[props.account])

  useEffect(()=>{
    getUserSubscriptions().then((res)=>{
        setSubscriptions(res.data);
    });
  },[])

  function loggingOut(){
      logout().then(()=>{
          window.location.href = "/";
      }).then(()=>{
          props.setAccount(null);
          props.setLoggedIn(false);
      })
  }

  return(
   
      <div className="">
        <div>
          <h1 className="title is-4">Konto andmed:</h1>
              {!props.account.email_validated &&
                  <div className="notification">
                    <p className="title is-6 mb-1">E-maili aadress kinnitamata</p>
                    <p className="">Pärast registreerumist saatsime teie e-mailile lingi, millelt saate e-maili aadressi kinnitada.</p>
                    <p className="has-text-centered mt-2"><button onClick={()=>{resendEmailActivation();setSentAgain(true)}} className="button is-small">Saada uuesti</button></p>
                    {sentAgain && <p className="has-text-centered small mt-2">Saadetud uuesti e-mailile {props.account.email}</p>}
                    </div>
              }
          <p>Nimi: {props.account.name} {props.account.surname}</p>
          <p>Email: {props.account.email}</p>
          
            {(!props.account.is_subscribed || props.account.is_trial) &&
                <Link to="/subscribe"><button className="button mr-2 mt-3 is-primary clickable">LIITU</button></Link>
            }
          <hr/>
          <p>
            <button className="button mr-2 is-small is-info is-light clickable" onClick={loggingOut}>Logi välja</button>
          </p>
          <hr/>


        </div>
        
      </div>
  );
}

