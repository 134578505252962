import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from "react-router-dom";
import { logout, getUserSubscriptions, createSubscription, getSubscriptionProduct } from './API';
import Tooltip from '@mui/material/Tooltip';
import Cookies from 'js-cookie';
import Checkbox from '@mui/material/Checkbox';
import * as settings from '../settings';


// props: subscribed, account
export default function SubscribeToProduct(props) {


  let { productname } = useParams();

  const [showForm, setShowForm] = useState(false);
  const [product, setProduct] = useState();
  const [agreed, setAgreed] = useState(false);
  const [remindToAgree, setRemindToAgree] = useState();


    useEffect(()=>{
  },[])


  useEffect(()=>{
        // if (props.account && props.account.is_subscribed && !props.account.is_trial) {
        //     alert("Olete juba liitunud!");
        // }
  },[props.account])

  useEffect(()=>{
    // getUserSubscriptions().then((res)=>{
    //     setSubscriptions(res.data);
    // });
    if (productname)
        getSubscriptionProduct(productname).then((res)=>{
            setProduct(res.data);
        });
  },[productname])

  function startSubscribingProcess(){
        if (!agreed) {
            setRemindToAgree(true);
            return;
        }
      createSubscription(product.name).then(subscriptionCreated, subscribeFail);
  }

  function subscriptionCreated(res){
        var billing = res.data;
        Cookies.set("transaction", billing.transactionid);
        var data =   {
                // publickey
                key : settings.SHOP_PUBLIC_KEY, 
                transaction : billing.transactionid,
                recurringRequired: true,
                recurringTitle: product.title,
                recurringDescription: 'Kuutasu '+billing.cost+"€",
                recurringConfirmation: 'Kinnitan, et soovin sõlmida püsimakse automaatseks kuutasu tasumiseks iga kuu.',
                amount : billing.cost, 
                currency : 'EUR', 
                email : props.account.email, 
                clientName : props.account.name + " " + props.account.surname, 
                locale : 'et', 
                name : settings.MY_SITE, 
                description : billing.reference, 
                completed : 'cardPaymentOK', 
                cancelled : 'cardPaymentFailed'
            }
            data.cost = product.cost;
        window.Maksekeskus.Checkout.initialize(data);
        window.Maksekeskus.Checkout.open();
    }
    function subscribeFail(res){
        alert("Hetkel liitumine pole veel võimalik.");
    }

    window.cardPaymentOK = function(data){
        alert("Hetkel pole liitumine võimalik.");
    }
      window.cardPaymentFailed = function(data){
        alert("Kaardiga tasumine ebaõnnestus");
    }


    function MKCallbackOnSuccess(data)
    {   
        alert( 'SUCCESS: The CC dialog returned: \r\n \r\n'+ JSON.stringify(data) ); 
    }
    function MKCallbackOnFail(data)
    {   
        alert( 'FAIL: The CC dialog returned: \r\n \r\n'+ JSON.stringify(data) ); 
    }


    if (!product) return <div className="container mt-5"><h1 className="title is-4">Pole hetkel saadaval...</h1></div>

  return(
   
      <div className="container has-text-centered mt-6">
      <div className="subscribe">
        <h1 className="title is-3 uppercase">{product.title}</h1>
        <p>{product.desc}</p> 
        <p>Hind: {product.cost}</p>
          <hr/>

            {props.loggedIn && 
              <div>
                <h1 className="title is-5">Konto andmed:</h1>
                <p>Nimi: {props.account.name} {props.account.surname}</p>
                <p>Email: {props.account.email}</p>
                <p> <span className="mr-1">Tellimuse olek: </span>
                    {props.account.is_subscribed && !props.account.is_trial &&
                        <span className="tag is-success uppercase">Kehtiv</span>
                    }
                    {props.account.is_trial &&
                        <Tooltip title="Registreerumisest alates 3 päeva on võimalik KOKOmini keskkonda piiramatult kasutada.">
                          <span className="ml-2 tag is-success">Prooviperiood</span>
                        </Tooltip>
                    }
                    {!props.account.is_subscribed &&
                        <span className="tag is-danger">Pole liitunud</span>
                    }
                </p>
                <hr/>
                    {!product &&
                             <div className="container has-text-centered mt-6">
                                <h1 className="title is-4">Liitumine</h1>
                                <p> Liitumine võimalik peagi!</p>
                            </div>
                     }

                     {product && 
                     <div>
                            <div>
                            <h1 className="title is-4">{product.title}</h1>
                            <p>{product.desc}</p>
                            <h1 className="title">{product && product.cost}€/kuus</h1>
                            <p className="small">Püsimakse - iga kuu teostatatakse makse automaatselt, kui vahepeal pole tellimus peatatud.</p>
                            <p className={"mt-2 agreeterms " +  (remindToAgree?'red':'')}>
                                    <Checkbox color="secondary" checked={agreed} size="small" onChange={()=>{setAgreed(!agreed)}} />
                                    <span onClick={()=>{setAgreed(!agreed);}} className={'clickable '}>Olen lugenud ja nõustun veebilehe <Link to="/terms/sales">tellimustingimustega</Link></span></p>
                            <p className="subtitle"></p>
                            </div>
                            <p><button className="mt-4 button is-primary" onClick={startSubscribingProcess}>Liitu</button></p>
                    </div>
                    }
                <hr/>

              </div>
            }
            {!props.loggedIn && 
              <div className="m-5 notification ">
                  <h1 className="m-3">Liitumiseks on vajalik esmalt <strong> konto luua </strong>.</h1>
                  <Link className="button is-primary is-small is-light mr-2" to="/register">Loo konto</Link>
                  <Link className="button is-primary is-small is-light" to="/login">Logi sisse</Link>
              </div>
            }

          </div>

      </div>
  );
}

