import React, { useEffect, useState } from 'react';

// props: message
// võiks teha nii, et kui tekib sõnum, näitab seda ja siis sets to null
export default function Notification(props) {

  // const [showNofitication, setShowNotification] = useState(false);

  useEffect(()=>{
      if(props.message && props.setMessage){
          setTimeout(()=>{

              props.setMessage(null);
              console.log(props.message);
          }, 3000);
      }
  }, [props.message]);

  function close(){
    if(props.setMessage)
      props.setMessage(null);
    if (props.close){
      props.close();
    }
  }

  return(
   
      <div className={'modal notification-modal '+(props.message ? 'is-active':'hidden')}>
          <div className="modal-background"></div>
              <div className="modal-content">

                {/* SISU */}
                  <div className="box">
                    <div>
                      <span className="is-pulled-right"><i className="fa-solid fa-xmark is-pulled-right" onClick={close}></i></span>
                      <span className="is-clearfix"></span>
                    </div>

                  {props.message &&
                      <h1 className="subtitle">{props.message}</h1>
                  }
                  </div>


              </div>  
      </div>
  );
}

